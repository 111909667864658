import qs from 'querystring';
import { getSelectedObjectTypes } from './getSelectedObjectTypes';

interface ICounty {
  id?: string;
  county?: string;
  locations?: any[];
}

interface IArea extends Omit<ICounty, 'locations'> {
  municipalityArea?: string;
}

export const getQueryObject = (value?: string) => {
  if (!value) return;
  const obj = qs.parse(value?.startsWith('?') ? value?.substring(1) : value);
  if (!(obj && Object.keys(obj)?.length)) return;
  const {
    a: available = null,
    c: counties = null,
    l: locations = null,
    mb: minBedroom = null,
    mv: mapView = null,
    ob: objectTypes = null,
    p: pageIndex = null,
    pfv: priceFrom = null,
    ptv: priceTo = null,
    q: query = null,
    s: sold = null,
    sd: subdealTypes = null,
    sfv: sizeFrom = null,
    stv: sizeTo = null,
    show_market_link = null
  } = obj;

  return {
    available: getBoolean(available),
    sold: getBoolean(sold),
    mapView: getBoolean(mapView),
    priceFrom: getNumber(priceFrom),
    priceTo: getNumber(priceTo),
    sizeFrom: getNumber(sizeFrom),
    sizeTo: getNumber(sizeTo),
    minBedroom: getNumber(minBedroom),
    counties: getCounties(counties),
    locations: getLocations(locations),
    objectTypes: getSelectedObjectTypes(objectTypes),
    query,
    subdealTypes: subdealTypes
      ? (typeof subdealTypes === 'string'
          ? [subdealTypes]
          : subdealTypes
        )?.filter((e) => e?.length)
      : null,
    show_market_link: getBoolean(show_market_link),
    pageIndex: getNumber(pageIndex)
  };
};

const getBoolean = (value?: string | string[] | null) => {
  if (!value || typeof value !== 'string') return false;
  return value?.toLowerCase() === 'true';
};

const getNumber = (value?: string | string[] | null) => {
  if (!value || typeof value !== 'string') return;
  const num = parseInt(value);
  if (isNaN(num) || !num) return;
  return num;
};

const getCounties = (value?: string | string[] | null) => {
  if (!value || !value?.length) return;
  const array =
    typeof value === 'string' ? (JSON.parse(value) as ICounty[]) : value;
  if (!Array.isArray(array) || !array?.length) return;
  return array;
};

const getLocations = (value?: string | string[] | null) => {
  if (!value || !value?.length) return;
  const array =
    typeof value === 'string' ? (JSON.parse(value) as IArea[]) : value;
  if (!Array.isArray(array) || !array?.length) return;
  return array;
};
