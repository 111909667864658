import * as React from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { SEARCH_ESTATE } from '../../../../client/__graphql__/cluster/queries/search/estate';
import {
  Estate,
  SearchEstateQuery,
  SearchEstateQueryVariables,
  SearchFilterEstate,
  SearchFilterLocation
} from '../../../__types__/generated/cluster';
import {
  Container,
  HeaderTitle,
  IAdCard,
  IConfig,
  ResultCount,
  Results,
  ResultsContainer,
  SectionHeader,
  TextInputContainer
} from './types/Index';
import { useSpring } from 'react-spring';
import { Waypoint } from 'react-waypoint';
import { CardContainer, PropertyCard } from './card/PropertyCard';
import { currency } from '../../../utils/format/currency';
import MDSpinner from 'react-md-spinner';
import AdCard, { adUtil } from './ads/Index';
import {
  ButtonIcon,
  CloseMapButtonDesktop,
  DivInfo,
  FilterButton,
  FilterToggles,
  Filters,
  List,
  ListHeader,
  ListItem,
  MapHeader,
  MapTitle,
  MapToggle,
  SaveButton,
  SearchHeader,
  SpinnerContainer
} from './filter/types';
import {
  Checkbox,
  Dropdown,
  FilterInput,
  RadioButtons,
  RangeSlider
} from './filter/Index';
import Pagination from './pagnation/Index';
import { MapIcon } from './asset/MapIcon';
import { useHistory, useLocation } from 'react-router';
import { util } from './util';
import debounce from 'lodash.debounce';
import { ENV_CONFIG } from '../../../__config__/env';
import { getDefaultAssignmentTypes } from './util/filter/getDefaultAssignmentTypes';

const { BRAND_ID } = ENV_CONFIG;
const defaultConfig = util.getConfigDefault();

const defaultFilter = util.getFilterDefault({
  published: defaultConfig?.published,
  limit: defaultConfig?.pagnation?.limit
});

enum LocationType {
  county = 'county',
  municipality = 'municipality',
  suburb = 'suburb'
}

const Index: React.FC = () => {
  const ref = React.useRef<null | HTMLDivElement>(null);
  const location = useLocation();
  const history = useHistory();
  const [initRun, setInitRun] = React.useState(true);
  const [mounted, setMounted] = React.useState(false);
  const [mapView, setMapView] = React.useState(false);
  const [hideHeader, setHideHeader] = React.useState(false);
  const [headerAnimationDone, setHeaderAnimationDone] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(false);
  const [results, setResults] = React.useState<(IAdCard | Estate)[]>([]);
  const [query, setQueryInput] = React.useState('');
  const [config, setConfig] = React.useState<IConfig>(defaultConfig);
  const [filter, setFilter] = React.useState<SearchFilterEstate>(defaultFilter);
  const [isTyping, setIsTyping] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(0);
  const clearIsTyping = debounce(() => setIsTyping(false), 2500);
  const setIsTypingValue = () => {
    clearIsTyping.cancel();
    setIsTyping(true);
    clearIsTyping();
  };

  const headerAnimation = useSpring({
    from: { opacity: 0, transform: `translate3d(0, 0px, 0)` },
    to: {
      opacity: mapView || hideHeader ? 0 : 1,
      transform:
        mapView || hideHeader
          ? `translate3d(0, 0px, 0)`
          : `translate3d(0, -24px, 0)`
    },
    onRest: () => {
      if (!headerAnimationDone) setHeaderAnimationDone(true);
    }
  });

  const onCompletedSearch = ({ search }: SearchEstateQuery) => {
    const adTypes = adUtil.getAdTypes();
    const {
      assignmentTypes,
      counties,
      estateTypes,
      municipalities,
      suburbs,
      sold
    } = util.filter.getOptions({
      config,
      initRun,
      filter,
      aggs: search?.estate?.aggregations
    });
    setConfig((prevState) => ({
      ...prevState,
      total: search?.estate?.total ?? 0,
      sold: sold,
      counties: counties,
      municipalities: municipalities,
      suburbs: suburbs,
      estateTypes: estateTypes,
      assignmentTypes: assignmentTypes
    }));
    setCurrentPage(filter?.pagination?.page ?? 0);

    if (
      search?.estate?.list?.length === config?.pagnation?.limit &&
      adTypes?.length
    ) {
      const listLength = search?.estate?.list?.length;
      const ads = [
        ...new Array(adTypes?.length < 4 ? adTypes?.length : 4)
      ]?.map<IAdCard>((_, index) => {
        const idx = Math.floor(Math.random() * adTypes?.length);
        const type = adTypes[idx];
        return {
          id: type,
          type: type,
          adIndex: Math.floor(Math.random() * listLength - 1)
        };
      });
      const array = search?.estate?.list?.reduce(
        (acc: (Estate | IAdCard)[], item: Estate, index: number) => {
          const ad = ads.find((e) => e?.adIndex === index);
          if (ad) acc.push(ad);
          acc.push(item);
          return acc;
        },
        []
      );
      if (array?.length) {
        array.splice(array.length - 1, 0, {
          type: 'kjop',
          adIndex: array.length - 1
        });
        const randomIndex = Math.floor(Math.random() * array.length - 1);
        array.splice(randomIndex, 0, {
          type: 'nordea_finans',
          adIndex: randomIndex
        });
      }
      setResults(array ?? []);
    } else {
      setResults(search?.estate?.list ?? []);
    }
    if (initRun) setInitRun(false);
  };

  const getInput = (): SearchEstateQueryVariables => {
    if (location?.search?.length) {
      const { filter, query } = util.query.getInit(location?.search);
      const querySearch =
        query && typeof query === 'string' ? query : undefined;
      return {
        finn: true,
        input: { brandId: BRAND_ID, query: querySearch },
        filter: {
          ...filter,
          assignmentTypes: util.filter.getDefaultAssignmentTypes(),
          isProjectUnit: false
        }
      };
    }
    return { input: { brandId: BRAND_ID }, filter: defaultFilter };
  };

  const { data, loading: initLoading } = useQuery<
    SearchEstateQuery,
    SearchEstateQueryVariables
  >(SEARCH_ESTATE, {
    skip: !initRun,
    ssr: true,
    fetchPolicy: 'no-cache',
    variables: getInput(),
    onCompleted: onCompletedSearch
  });

  const [search, { loading }] = useLazyQuery<
    SearchEstateQuery,
    SearchEstateQueryVariables
  >(SEARCH_ESTATE, {
    ssr: false,
    fetchPolicy: 'no-cache',
    onError: (error) => {},
    onCompleted: onCompletedSearch
  });

  React.useEffect(() => {
    if (mounted && !initRun) {
      const { assignmentTypes } = filter ?? {};
      search({
        variables: {
          finn: true,
          input: {
            brandId: BRAND_ID,
            query: query?.length > 1 ? query : undefined
          },
          filter: {
            ...filter,
            assignmentTypes: assignmentTypes?.length
              ? assignmentTypes
              : getDefaultAssignmentTypes()
          }
        }
      });
    }
  }, [filter, query]);

  React.useEffect(() => {
    if (mounted) {
      if (query.length) {
        setIsTypingValue();
      } else {
        setIsTyping(false);
      }
    }
  }, [query]);

  React.useEffect(() => {
    if (!initLoading && !mounted) {
      setMounted(true);
      if (location?.search) {
        const { filter, query } = util.query.getInit(location?.search);
        const querySearch = query && typeof query === 'string' ? query : ``;
        setQueryInput(querySearch);
        setFilter(filter);
      }
    }
  }, [initLoading]);

  const setLocation = async (
    type: LocationType,
    value: string,
    parentId?: string
  ) => {
    if (!value?.length) return;
    const array: SearchFilterLocation[] = [];
    const { counties = [], municipalities = [], suburbs = [] } = config ?? {};

    switch (type) {
      case LocationType.county:
        {
          counties
            ?.filter((e) => e?.value === value)
            ?.forEach((e) => {
              const { selected = false } = e;
              e.selected = !selected;
            });

          const { selected } = counties?.find((e) => e?.value === value) ?? {};
          if (!selected) {
            municipalities
              ?.filter((e) => e?.parentId === value && e?.selected)
              ?.forEach((e) => {
                e.selected = false;
              });
            suburbs
              ?.filter((e) => e?.parentId === value && e?.selected)
              ?.forEach((e) => {
                e.selected = false;
              });
          }
        }
        break;
      case LocationType.municipality:
        {
          if (parentId) {
            municipalities
              ?.filter((e) => e?.value === value && e?.parentId === parentId)
              ?.forEach((e) => {
                const { selected = false, parentId } = e;
                e.selected =
                  !selected &&
                  counties?.length > 0 &&
                  counties?.findIndex(
                    (e) => e?.value === parentId && e?.selected
                  ) > -1;
              });
          }
        }
        break;
      case LocationType.suburb:
        {
          if (parentId) {
            suburbs
              ?.filter((e) => e?.value === value && e?.parentId === parentId)
              ?.forEach((e) => {
                const { selected = false, parentId } = e;
                e.selected =
                  !selected &&
                  counties?.length > 0 &&
                  counties?.findIndex(
                    (e) => e?.value === parentId && e?.selected
                  ) > -1;
              });
          }
        }
        break;
    }

    counties
      ?.filter((e) => e?.selected && e?.value)
      ?.forEach((county) => {
        const { value, selected = false } = county;
        if (
          value &&
          selected &&
          array?.findIndex((y) => value === y?.county) < 0
        ) {
          array.push({ county: value, municipalities: [], suburbs: [] });
        }
      });

    municipalities
      ?.filter((e) => e?.selected && e?.value && e?.parentId)
      ?.forEach((muni) => {
        const { value, parentId, selected = false } = muni;
        if (value && parentId && selected) {
          const idx = array?.findIndex((e) => e?.county === parentId);
          if (idx > -1) {
            if (!array[idx]?.municipalities?.includes(value)) {
              array[idx]?.municipalities?.push(value);
            }
          } else {
            array.push({
              county: parentId,
              municipalities: [value],
              suburbs: []
            });
          }
        }
      });

    suburbs
      ?.filter((e) => e?.selected && e?.value && e?.parentId)
      ?.forEach((sub) => {
        const { value, parentId, selected = false } = sub;
        if (value && parentId && selected) {
          const idx = array?.findIndex((e) => e?.county === parentId);
          if (idx > -1) {
            if (!array[idx]?.suburbs?.includes(value)) {
              array[idx]?.suburbs?.push(value);
            }
          } else {
            array.push({
              county: parentId,
              suburbs: [value],
              municipalities: []
            });
          }
        }
      });

    setConfig((prevState) => ({
      ...prevState,
      counties,
      municipalities,
      suburbs
    }));

    setFilter((prevState) => ({
      ...prevState,
      locations: array,
      pagination: {
        ...prevState.pagination,
        page: 0
      }
    }));
  };

  const onClickSaveQuery = () => {
    const search = util.query.setQuery({ filter, config, query });
    history.push(`boligsok${search && search?.length ? `?${search}` : ``}`);
  };

  const getSubOptions = (type: LocationType, parentId?: string) => {
    if (!parentId) return [];
    switch (type) {
      case LocationType.municipality:
        return config?.municipalities?.filter((e) => e?.parentId === parentId);
        break;
      case LocationType.suburb:
        return config?.suburbs?.filter((e) => e?.parentId === parentId);
        break;
    }
    return [];
  };

  return (
    <Container style={{ paddingTop: '100px' }} ref={ref}>
      <SectionHeader style={headerAnimation}>
        <Waypoint
          topOffset={'150px'}
          onEnter={(e) => {
            if (hideHeader) setHideHeader(false);
          }}
          onLeave={(e) => {
            if (!hideHeader) setHideHeader(true);
          }}
        />
        <HeaderTitle>Søk etter eiendommer</HeaderTitle>
      </SectionHeader>
      {headerAnimationDone ? (
        <>
          <Filters
            className={mapView ? 'map' : ``}
            mapView={mapView}
            show={showFilter}
          >
            <SearchHeader>
              {mapView ? (
                <MapHeader>
                  <MapTitle>Søk i kart</MapTitle>
                  <CloseMapButtonDesktop onClick={() => setMapView(!mapView)}>
                    Lukk kart
                  </CloseMapButtonDesktop>
                </MapHeader>
              ) : null}
              <FilterToggles mapView={mapView}>
                {!mapView ? (
                  <>
                    <SaveButton
                      onClick={(e) => {
                        e?.preventDefault();
                        onClickSaveQuery();
                      }}
                    >
                      Lagre søk
                    </SaveButton>
                    {mapView ? (
                      <MapToggle
                        onClick={() => {
                          if (showFilter) setShowFilter(false);
                          setMapView(!mapView);
                        }}
                      >
                        <ButtonIcon>
                          <MapIcon />
                        </ButtonIcon>
                        Kart
                      </MapToggle>
                    ) : null}
                  </>
                ) : null}
                <TextInputContainer>
                  <FilterInput
                    value={query}
                    onChange={debounce(
                      (val) => {
                        setQueryInput(val ?? ``);
                      },
                      1000,
                      { leading: false }
                    )}
                  />
                </TextInputContainer>
                <FilterButton
                  className="fullWidth primary"
                  mobile
                  onClick={() => setShowFilter(false)}
                >
                  {`Se resultater ${
                    config?.total ?? data?.search?.estate?.total ?? 0
                  }`}
                </FilterButton>
              </FilterToggles>
            </SearchHeader>
            {config?.counties?.length ? (
              <>
                <ListHeader>Områder</ListHeader>
                <List>
                  {config?.counties?.map((e, index) => {
                    const {
                      label,
                      value: rootValue,
                      count,
                      selected = false
                    } = e;
                    if (rootValue === null) return null;
                    const hasSuburbs =
                      config?.suburbs?.findIndex(
                        (e) => e?.parentId === rootValue
                      ) > -1;
                    return (
                      <ListItem key={`li${index}`}>
                        <Checkbox
                          checked={selected}
                          label={label ?? rootValue}
                          value={rootValue}
                          count={count}
                          onClick={(value: string | number) => {
                            setLocation(
                              LocationType.county,
                              typeof value === 'string'
                                ? value
                                : value?.toString()
                            );
                          }}
                          subOptions={
                            selected
                              ? getSubOptions(
                                  hasSuburbs
                                    ? LocationType.suburb
                                    : LocationType.municipality,
                                  rootValue
                                )
                              : []
                          }
                          onSubClick={(value: string | number) => {
                            setLocation(
                              hasSuburbs
                                ? LocationType.suburb
                                : LocationType.municipality,
                              typeof value === 'string'
                                ? value
                                : value?.toString(),
                              rootValue
                            );
                          }}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </>
            ) : null}
            {config?.sold?.options?.length ? (
              <>
                <ListHeader>Type søk</ListHeader>
                <List>
                  {config?.sold?.options?.map((e, index) => {
                    const { label, value, count } = e;
                    if (value === null) return null;
                    return (
                      <ListItem key={`li${index}`}>
                        <Checkbox
                          checked={config?.sold?.selectedValue === e?.value}
                          label={label ?? value}
                          value={value}
                          count={count}
                          onClick={(value: string | number) => {
                            const selectedValue =
                              value !== config?.sold?.selectedValue
                                ? value?.toString()
                                : null;
                            setConfig((prevState) => ({
                              ...prevState,
                              sold: {
                                ...prevState.sold,
                                selectedValue: selectedValue
                              }
                            }));
                            setFilter((prevState) => ({
                              ...prevState,
                              sold: selectedValue
                                ? selectedValue === '1'
                                : undefined,
                              pagination: {
                                ...prevState.pagination,
                                page: 0
                              }
                            }));
                          }}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </>
            ) : null}
            {config?.assignmentTypes?.length ? (
              <>
                <ListHeader>Nytt / brukt / fritid</ListHeader>
                <List>
                  {config?.assignmentTypes?.map((e, index) => {
                    const { label, value, count, selected = false } = e;
                    if (!value?.length) return null;
                    return (
                      <ListItem key={`li${index}`}>
                        <Checkbox
                          checked={selected}
                          label={label}
                          value={index}
                          count={count}
                          onClick={(value: string | number) => {
                            const options =
                              config?.assignmentTypes?.map((o, index) => {
                                if (e?.label !== o?.label) return o;
                                const { selected = false } = o;
                                return { ...o, selected: !selected };
                              }) ?? [];
                            setConfig((prevState) => ({
                              ...prevState,
                              assignmentTypes: options
                            }));
                            const selected = options
                              ?.filter((o) => o?.selected && o?.value?.length)
                              ?.map((o) => o?.value ?? [])
                              ?.reduce((pV, cV, current) => {
                                if (cV?.length) pV.push(...cV);
                                return pV;
                              }, []);
                            setFilter((prevState) => ({
                              ...prevState,
                              assignmentTypes: selected?.length
                                ? selected
                                : undefined,
                              pagination: {
                                ...prevState.pagination,
                                page: 0
                              }
                            }));
                          }}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </>
            ) : null}
            {config?.estateTypes?.length ? (
              <>
                <ListHeader>Boligtype</ListHeader>
                <List>
                  {config?.estateTypes?.map((e, index) => {
                    const { label, value, count, selected = false } = e;
                    if (!value?.length) return null;
                    return (
                      <ListItem key={`li${index}`}>
                        <Checkbox
                          checked={selected}
                          label={label}
                          value={index}
                          count={count}
                          onClick={(value: string | number) => {
                            const options =
                              config?.estateTypes?.map((o, index) => {
                                if (e?.label !== o?.label) return o;
                                const { selected = false } = o;
                                return { ...o, selected: !selected };
                              }) ?? [];
                            setConfig((prevState) => ({
                              ...prevState,
                              estateTypes: options
                            }));
                            const selected = options
                              ?.filter((o) => o?.selected && o?.value?.length)
                              ?.map((o) => o?.value ?? ``);
                            setFilter((prevState) => ({
                              ...prevState,
                              estateTypes: selected ?? undefined
                            }));
                          }}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </>
            ) : null}
            <ListHeader>Prisantydning</ListHeader>
            <DivInfo style={{ display: 'none' }}>
              {`${
                filter?.price?.from && filter?.price?.from > config?.price?.min
                  ? 'Fra'
                  : 'Under'
              } ${currency({
                number: filter?.price?.from ?? config?.price?.min
              })} - ${
                filter?.price?.to && filter?.price?.to < config?.price?.max
                  ? ``
                  : 'Over '
              }${currency({
                number: filter?.price?.to ?? config?.price?.max
              })}`}
            </DivInfo>
            <RangeSlider
              showInfo
              min={config.price.min}
              max={config.price.max}
              step={config.price.step}
              selected={{ from: filter?.price?.from, to: filter?.price?.to }}
              printValue={({ value, isFromValue }) => {
                return `${currency({ number: value })}${
                  !isFromValue ? ` kr` : ``
                }`;
              }}
              handleChange={(from, to) => {
                setFilter((prevState) => ({
                  ...prevState,
                  price: {
                    from: from && from !== config.price.min ? from : undefined,
                    to: to && to !== config.price.max ? to : undefined
                  },
                  pagination: {
                    ...prevState.pagination,
                    page: 0
                  }
                }));
              }}
            />
            <ListHeader>Størrelse</ListHeader>
            <DivInfo
              style={{ display: 'none' }}
              dangerouslySetInnerHTML={{
                __html: `${
                  filter?.size?.from && filter?.size?.from > config?.size?.min
                    ? 'Fra'
                    : 'Under'
                } ${filter?.size?.from ?? config?.size?.min}m<sup>2</sup> - ${
                  filter?.size?.to && filter?.size?.to < config?.size?.max
                    ? ``
                    : 'Over '
                }${filter?.size?.to ?? config?.size?.max}m<sup>2</sup>`
              }}
            ></DivInfo>
            <RangeSlider
              showInfo
              min={config.size.min}
              max={config.size.max}
              step={config.size.step}
              selected={{ from: filter?.size?.from, to: filter?.size?.to }}
              printValue={({ value }) => {
                return `${value}m<sup>2</sup>`;
              }}
              handleChange={(from, to) => {
                setFilter((prevState) => ({
                  ...prevState,
                  size: {
                    from: from && from !== config.size.min ? from : undefined,
                    to: to && to !== config.size.max ? to : undefined
                  },
                  pagination: {
                    ...prevState.pagination,
                    page: 0
                  }
                }));
              }}
            />
            <ListHeader>Soverom</ListHeader>
            <RadioButtons
              selectedValue={filter?.bedrooms?.from}
              handleChange={(selected?: string | number) => {
                if (typeof selected === 'number') {
                  if (filter?.bedrooms?.from !== selected) {
                    setFilter((prevState) => ({
                      ...prevState,
                      bedrooms: {
                        ...prevState.bedrooms,
                        from: selected
                      }
                    }));
                  } else {
                    setFilter((prevState) => ({
                      ...prevState,
                      bedrooms: {
                        ...prevState.bedrooms,
                        from: undefined
                      }
                    }));
                  }
                }
              }}
              items={[
                { label: '1 +', value: 1 },
                { label: '2 +', value: 2 },
                { label: '3 +', value: 3 },
                { label: '4 +', value: 4 },
                { label: '5 +', value: 5 }
              ]}
            />
          </Filters>
          <ResultsContainer>
            <FilterToggles mapView={mapView}>
              <FilterButton
                mobile
                className="noFlex"
                onClick={() => setShowFilter(!showFilter)}
              >
                Søkekriterier
              </FilterButton>
              <Dropdown
                icon
                options={config.sort.options}
                selected={config?.sort?.options?.find(
                  (e) => e?.value === filter?.sort
                )}
                handeChange={(index: number) => {
                  const value =
                    config?.sort?.options[index]?.value ?? undefined;
                  if (value && value !== filter?.sort) {
                    setFilter((prevState) => ({
                      ...prevState,
                      sort: value,
                      pagination: {
                        ...prevState.pagination,
                        page: 0
                      }
                    }));
                  }
                }}
              />
              <ResultCount>
                {loading || isTyping || initLoading ? (
                  <>
                    <SpinnerContainer>
                      <MDSpinner singleColor="#e8c893" />
                    </SpinnerContainer>
                    søker...
                  </>
                ) : (
                  <>
                    <strong>
                      {config.total ?? data?.search?.estate?.total ?? 0}
                    </strong>{' '}
                    eiendommer
                  </>
                )}
              </ResultCount>
            </FilterToggles>
            <Results>
              {results?.map((e, index) => {
                const { type = null, estateId = null } = (e as Estate) ?? {};
                if (estateId) {
                  return (
                    <PropertyCard key={`e-${estateId}-${index}`} estate={e} />
                  );
                }
                if (!type) return null;
                return (
                  <AdCard
                    key={`a-${type}-${index}`}
                    type={type}
                    externalContainer={CardContainer}
                  />
                );
              })}
            </Results>
            <Pagination
              isMapView={mapView}
              show={!mapView && config?.total != null && config?.total > 0}
              total={config?.total ?? data?.search?.estate?.total ?? 0}
              page={currentPage ?? 0}
              itemsPrPage={config?.pagnation?.limit ?? 0}
              setPage={(page: number) => {
                if (ref?.current) {
                  ref?.current?.scrollIntoView({
                    behavior: 'smooth'
                  });
                }
                setFilter((prevState) => ({
                  ...prevState,
                  pagination: {
                    ...filter.pagination,
                    page
                  }
                }));
              }}
            />
          </ResultsContainer>
        </>
      ) : null}
    </Container>
  );
};

export default Index;
