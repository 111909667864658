import * as React from 'react';
import styled from 'styled-components';
import { ProjectStatistics } from '../../../../__types__/generated/cluster';

export const ProjectStatInfo: React.FC<{
  teaser?: boolean;
  statistics?: Partial<ProjectStatistics>;
}> = ({ teaser, statistics }) => {
  if (teaser) return <Container>Kommer for salg</Container>;
  return null;
  // TODO ADD WHEN FIX OF COUNT IS APPLIED
  /*if (!statistics) return null;
  const { total = 0, available = 0, sold = 0 } = statistics;
  if (total < 1) return null;
  if (sold === total) {
    return <Container>Utsolgt</Container>;
  }
  if (sold > total / 2) {
    return <Container>{`${sold} av ${total} solgt`}</Container>;
  }
  if (available < 1) return null;
  return <Container>{`${available} av ${total} ledige`}</Container>;*/
};

const Container = styled.div`
  position: absolute;
  bottom: 2.6em;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 9px 12px;
  background: rgba(0, 0, 0, 0.666);
  color: #fff;
  z-index: 3;
  pointer-events: none;
`;
