import { AssignmentType } from '../../../../../__types__/generated/cluster';

export const getDefaultAssignmentTypes = () => {
  return [
    AssignmentType.Buy,
    AssignmentType.Commercial,
    AssignmentType.DeceasedEstate,
    AssignmentType.ForcedSale,
    AssignmentType.Foreclosure,
    AssignmentType.Leisure,
    AssignmentType.NewDevelopment,
    AssignmentType.NewDevelopmentCommercial,
    AssignmentType.NewDevelopmentLeisure,
    AssignmentType.Plot,
    AssignmentType.ReSale,
    AssignmentType.Sale
  ];
};
