import * as React from 'react';
import styled from 'styled-components';

interface IArgs {
  className?: string;
  hide?: boolean;
}

const ImageIcon: React.FC<IArgs> = ({ className, hide = false }) => {
  return (
    <Container className={`${className ?? ``} ${hide ? 'hide' : ''}`}>
      <Logo
        alt="PM"
        title="PM"
        src="https://reeltimeprod-ahebbuatfggte7f2.z01.azurefd.net/privatmegleren/pm-kvadrat-logo.svg"
      />
    </Container>
  );
};

export default ImageIcon;

const Container = styled.div`
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &.bkg {
    background-color: rgba(0, 0, 0, 0.5);
  }

  &.hide {
    opacity: 0;
  }
`;

export const Logo = styled.img`
  width: 8rem;
  height: 8rem;
  margin: 0;

  ${({ theme }) => theme.search.mediaQueries.desktop`
		width: 4rem;
		height: 4rem;
	`};
`;

export const Mark = styled.span`
  display: flex;
  width: 8rem;
  height: 8rem;
  margin: 0;

  svg {
    width: inherit;
    height: auto;

    path {
      fill: ${({ theme }) => theme.search.colors.gold.primary};
    }
  }

  ${({ theme }) => theme.search.mediaQueries.desktop`
		width: 4rem;
		height: 4rem;
	`};
`;
