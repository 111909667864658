import * as React from 'react';
import styled from 'styled-components';
import { Size } from '../../../../__types__/generated/cluster';

export const Area: React.FC<{ size?: Partial<Size>; plot?: number }> = ({
  size,
  plot
}) => {
  if (size?.grossInternalArea)
    return <AreaInfo from={size?.grossInternalArea} to={size?.grossInternalAreaTo} />;

  if (size?.primaryArea)
    return <AreaInfo from={size?.primaryArea} to={size?.primaryAreaTo} />;

  if (size?.usableArea)
    return <AreaInfo from={size?.usableArea} to={size?.usableAreaTo} />;

  if (size?.grossArea)
    return <AreaInfo from={size?.grossArea} to={size?.grossAreaTo} />;

  if (plot)
    return <AreaInfo from={plot} />;
  return null;
};

const AreaInfo: React.FC<{ from?: number; to?: number }> = ({ from, to }) => {
  if (!from) return null;
  if (to && from < to) {
    return (
      <Container
        dangerouslySetInnerHTML={{
          __html: `${Math.trunc(from)}-${Math.trunc(to)}m<sup>2</sup>`
        }}
      />
    );
  }

  return (
    <Container
      dangerouslySetInnerHTML={{ __html: `${Math.trunc(from)}m<sup>2</sup>` }}
    />
  );
};

const Container = styled.span`
  && {
    display: inline-block;
  }
`;
