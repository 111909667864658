import { queryUtil } from './query';
import { filterUtil } from './filter';
import { getConfigDefault } from './getConfigDefault';
import { getFilterDefault } from './getFilterDefault';
import { checklistIsChecked } from './checklistIsChecked';
import { isNewDevelopment } from './isNewDevelopment';

export const util = {
  query: queryUtil,
  filter: filterUtil,
  getConfigDefault,
  getFilterDefault,
  checklistIsChecked,
  isNewDevelopment
};
