import * as React from 'react';
import styled from 'styled-components';
import { Button } from './Button';
import { Image as EstateImage } from '../../../../../__types__/generated/cluster';
import Image from './Index';
import LazyLoad from 'react-lazyload';
import { MarkIcon } from '../../asset/MarkIcon';
import ImageIcon from './ImageIcon';

interface IImage {
  id: string;
  loaded: boolean;
  src?: string;
}

const Carousel: React.FC<{
  estateId: string;
  list: Partial<EstateImage>[];
}> = ({ estateId, list, children }) => {
  const [prevIndex, setPrevIndex] = React.useState(-1);
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const bulletRef = React.useRef<HTMLDivElement | null>(null);
  const [images, setImages] = React.useState<IImage[]>(
    list?.map<IImage>((e, index) => {
      return { id: e?.imageId ?? `img${index}`, src: e?.url, loaded: false };
    }) ?? []
  );

  React.useEffect(() => {
    if (bulletRef?.current) {
      const bulletIndex = currentIndex - 2;
      bulletRef.current.style.setProperty(
        'transform',
        `translateX(-${bulletIndex > 0 ? 18 * bulletIndex : 0}px)`
      );
    }
  }, [currentIndex, bulletRef]);

  const onPrev = () => {
    const idx = currentIndex - 1 < 0 ? images?.length - 1 : currentIndex - 1;
    setPrevIndex(currentIndex);
    setCurrentIndex(idx);
  };
  const onNext = () => {
    const idx = currentIndex + 1 < images?.length - 1 ? currentIndex + 1 : 0;
    setPrevIndex(currentIndex);
    setCurrentIndex(idx);
  };

  if (!images?.length) {
    return (
      <Container>
        <Image />
        {children}
      </Container>
    );
  }

  const getClassName = (index: number) => {
    const bulletIndex = currentIndex - 2;
    const array: string[] = [];
    if (index === currentIndex) array.push('active');
    if (bulletIndex >= 2 && index < currentIndex - 1) array.push('small');
    if (index > currentIndex + 1) array.push('small');
    if (!array?.length) return;
    return array?.join(' ');
  };

  return (
    <Container>
      <Button id={estateId} onClick={onPrev} />
      <LazyLoad
        height={250}
        placeholder={<ImageIcon />}
        throttle={100}
        offset={50}
      >
        {prevIndex > -1 &&
          prevIndex != currentIndex &&
          images?.map((img, index) => {
            if (index !== prevIndex || !img) return null;
            return (
              <Image
                key={`img${index}`}
                className="image"
                src={img?.src}
                loaded={img?.loaded}
              />
            );
          })}
        {images?.map((img, index) => {
          if (index !== currentIndex || !img) return null;
          return (
            <Image
              key={`img${index}`}
              src={img?.src}
              loaded={img?.loaded}
              className="image"
              classNameIcon={prevIndex !== -1 ? 'bkg' : undefined}
              showIcon={true}
              onLoad={() => {
                if (img?.loaded) return;
                setImages((prev) => {
                  return prev.map((item, imageIndex) => {
                    if (index !== imageIndex) return item;
                    return { ...item, loaded: true };
                  });
                });
              }}
            />
          );
        })}
      </LazyLoad>
      <Button id={estateId} pos="right" onClick={onNext} />
      <BulletContainer>
        <BulletWrapper ref={bulletRef}>
          {images?.map((e, index) => {
            return (
              <Bullet key={`bullet${index}`} className={getClassName(index)} />
            );
          })}
        </BulletWrapper>
      </BulletContainer>
      {children}
    </Container>
  );
};

export default Carousel;

const Container = styled.div`
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  overflow: hidden;
  background: #333;
  &:hover {
    .left,
    .right {
      display: block;
    }
  }

  .left,
  .right {
    display: none;
  }

  &:before {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  .image {
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      transition: ease-in-out 500ms transform;
    }
  }
`;

const BulletContainer = styled.div`
  position: absolute;
  bottom: 1em;
  max-width: 6em;
  margin: 0 auto;
  overflow: clip;
  left: 0;
  right: 0;
`;

const BulletWrapper = styled.div`
  display: inline-flex;
  transition: transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transform: translateX(0px);
`;

const Bullet = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-left: 4px;
  margin-right: 4px;
  background-color: #cccccc;
  transition: scale 0.2s ease-in-out;
  &.active {
    background-color: #ffffff;
  }
  &.small {
    scale: 0.6;
  }
`;
