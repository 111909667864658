import { lighten } from 'polished';
import * as React from 'react';
import styled from 'styled-components';
import { IOptionItem } from '../types/Index';
import { ChevronIcon } from '../asset/ChevronIcon';

interface IArgs {
  icon?: boolean;
  disabled?: boolean;
  selected?: IOptionItem<any>;
  options: IOptionItem<any>[];
  handeChange: (index: number) => void;
}

const Dropdown: React.FC<IArgs> = ({
  selected,
  disabled = false,
  icon = false,
  options,
  handeChange
}) => {
  const [show, setShow] = React.useState(false);

  return (
    <Container className="dropdown" disabled={disabled}>
      <Button className={show ? `open` : ``} onClick={() => setShow(!show)}>
        {!disabled ? (
          <>
            Sorter etter: {selected?.label ?? ''}
            {icon ? (
              <Icon>
                <ChevronIcon />
              </Icon>
            ) : null}
          </>
        ) : (
          'Sorter etter: søkeord'
        )}
      </Button>
      {show && options?.length ? (
        <Options>
          {options?.map((e, index) => {
            if (!e?.value || !e?.label) return null;
            return (
              <Option
                key={`opt${index}`}
                onClick={() => {
                  setShow(false);
                  handeChange(index);
                }}
              >
                {e?.label}
              </Option>
            );
          })}
        </Options>
      ) : null}
    </Container>
  );
};

export default Dropdown;

const Container = styled.div<{ disabled?: boolean }>`
  position: relative;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
`;
const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ theme }) => theme.search.type.size1.fontSize};
  ${({ theme, dangerouslySetInnerHTML }) =>
    dangerouslySetInnerHTML ? null : 'padding: 0px 0.75rem'};
  background: ${({ theme }) => theme.search.colors.primary3Light};
  border: ${({ theme }) => `1px solid ${theme.search.colors.grey.dark}`};
  //color: ${({ theme }) => lighten(0.4, theme.search.colors.grey.dark)};
  color: white;
  font-size: ${({ theme }) => theme.search.type.filter.fontSize};
  text-decoration: none;
  cursor: pointer;

  &:focus {
    outline: 0;
  }

  &:hover,
  &.open {
    background: ${({ theme }) => theme.search.colors.gold.primary};
    color: #000;
    svg {
      path {
        fill: #000;
      }
    }
  }

  &.open {
    svg {
      transform: rotate(180deg);
    }
  }

  svg {
    display: flex;
    transition: 300ms ease-in transform;
    width: ${({ theme }) => theme.search.type.size4.fontSize};
    height: ${({ theme }) => theme.search.type.size4.fontSize};
    margin-left: 9px;
    path {
      fill: ${({ theme }) => lighten(0.4, theme.search.colors.grey.dark)};
    }
  }

  ${({ theme }) => theme.search.mediaQueries.tablet`
		justify-content: flex-start;
		height: 2.25rem;
		min-width: 200px;
	`};
  ${({ theme }) => theme.search.mediaQueries.desktop`
		justify-content: flex-start;
		height: 2.25rem;
		min-width: 200px;
	`};
`;

const Options = styled.ul`
  font-size: ${({ theme }) => theme.search.type.filter.fontSize};
  display: flex;
  flex-flow: column;
  position: absolute;
  top: ${({ theme }) => theme.search.type.size1.fontSize};
  color: white;
  left: 0;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  z-index: 10;
  list-style: none;
  cursor: pointer;
  background-color: ${({ theme }) => theme.search.colors.grey.dark};
  ${({ theme }) => theme.search.mediaQueries.tablet`
		top: 2.25rem;
	`};
  ${({ theme }) => theme.search.mediaQueries.desktop`
		top: 2.25rem;
	`};

  &:focus {
    color: white;
  }
`;

const Option = styled.li`
  display: flex;
  white-space: nowrap;
  align-items: center;
  height: ${({ theme }) => theme.search.type.size1.fontSize};
  //max-width: calc(100% - 10px);
  width: 100%;
  padding: 0px 0.75rem;
  background: ${({ theme }) => theme.search.colors.grey.dark};
  //border-bottom: 1px solid #000;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.search.colors.gold.primary};
    color: #000;
    //color: white;
  }

  &:last-child {
    border: 0;
  }

  ${({ theme }) => theme.search.mediaQueries.tablet`
		height: 2.25rem;
	`};
  ${({ theme }) => theme.search.mediaQueries.desktop`
		height: 2.25rem;
	`};
`;

const Icon = styled.div`
  ${({ theme }) => theme.search.mediaQueries.tablet`
		margin-left: auto;
	`};
  ${({ theme }) => theme.search.mediaQueries.desktop`
		margin-left: auto;
	`};
`;
