import {
  SearchEstateAggregations,
  SearchFilterEstate
} from '../../../../../__types__/generated/cluster';
import { IConfig, IOptionItem } from '../../types/Index';

interface IArgs {
  config: IConfig;
  filter?: SearchFilterEstate;
  aggs?: SearchEstateAggregations;
  initRun: boolean;
}

export const getOptions = (
  args: IArgs
): Pick<
  IConfig,
  | 'assignmentTypes'
  | 'sold'
  | 'counties'
  | 'municipalities'
  | 'estateTypes'
  | 'suburbs'
> => {
  const { config, filter, aggs, initRun } = args;
  const assignmentTypes = config?.assignmentTypes?.map((opt, index) => {
    const { value = [] } = opt;
    if (!value || !value.length) return opt;
    const count =
      aggs?.assignmentTypes
        ?.filter(
          (x) => x?.key && x?.count && x?.count > 0 && value?.includes(x?.key)
        )
        ?.map((x) => x?.count ?? 0)
        ?.reduce((pV, cV) => pV + cV, 0) ?? 0;
    const selected = filter?.assignmentTypes
      ? value?.every((e) => filter?.assignmentTypes?.includes(e))
      : false;
    return { ...opt, count, selected };
  });

  const sold = config?.sold?.options?.map((opt) => {
    const { value } = opt;
    const obj = aggs?.sold?.find((e) => e?.key === value);
    if (!obj) return { ...opt, count: 0 };
    const { count = 0 } = obj;
    return { ...opt, count };
  });
  const selectedSold =
    filter?.sold !== undefined
      ? filter?.sold
        ? '1'
        : '0'
      : config?.sold?.selectedValue;

  if (initRun) {
    const counties =
      aggs?.counties?.map<IOptionItem<string>>((e, index) => ({
        label: e?.label ?? e?.key ?? ``,
        value: e?.key ?? null,
        count: e?.count,
        selected: filter?.locations?.length
          ? filter?.locations?.findIndex((x) => x?.county === e?.key) > -1
          : false
      })) ?? [];

    const municipalities =
      aggs?.municipalities?.map<IOptionItem<string>>((e, index) => ({
        label: e?.label ?? e?.key ?? ``,
        value: e?.key ?? null,
        count: e?.count,
        parentId: e?.parentKey,
        selected:
          filter?.locations?.length && e?.parentKey && e?.key
            ? filter?.locations?.findIndex(
                (x) =>
                  e?.parentKey &&
                  e?.key &&
                  x?.county === e?.parentKey &&
                  x?.municipalities?.length &&
                  x?.municipalities?.includes(e?.key)
              ) > -1
            : false
      })) ?? [];

    const suburbs =
      aggs?.suburbs?.map<IOptionItem<string>>((e, index) => ({
        label: e?.label ?? e?.key ?? ``,
        value: e?.key ?? null,
        count: e?.count,
        parentId: e?.parentKey,
        selected:
          filter?.locations?.length && e?.parentKey && e?.key
            ? filter?.locations?.findIndex(
                (x) =>
                  e?.parentKey &&
                  e?.key &&
                  x?.county === e?.parentKey &&
                  x?.suburbs?.length &&
                  x?.suburbs?.includes(e?.key)
              ) > -1
            : false
      })) ?? [];

    const estateTypes =
      aggs?.estateTypes?.map<IOptionItem<string>>((e, index) => ({
        label: e?.label ?? e?.key ?? ``,
        value: e?.key ?? null,
        count: e?.count,
        selected: config?.estateTypes?.length
          ? config?.estateTypes?.findIndex(
              (x) => x?.value === e?.key && x?.selected
            ) > -1
          : false
      })) ?? [];

    return {
      assignmentTypes,
      counties,
      municipalities,
      suburbs,
      estateTypes,
      sold: {
        selectedValue: selectedSold,
        options: sold
      }
    };
  }

  const counties = config?.counties?.map((opt) => {
    const { value } = opt;
    if (!value) return { ...opt, count: 0 };
    const obj = aggs?.counties?.find((e) => e?.key === opt?.value);
    const selected = filter?.locations?.length
      ? filter?.locations?.findIndex((e) => e?.county === value) > -1
      : false;
    if (!obj) return { ...opt, count: 0, selected };
    const { count = 0 } = obj;
    return { ...opt, count, selected };
  });

  const municipalities = config?.municipalities?.map((opt) => {
    const { value, parentId } = opt;
    if (!value || !parentId) return { ...opt, selected: false, count: 0 };
    const obj = aggs?.municipalities?.find(
      (e) => e?.key === value && e?.parentKey == parentId
    );
    const selected = filter?.locations?.length
      ? filter?.locations?.findIndex(
          (e) => e?.county === parentId && e?.municipalities?.includes(value)
        ) > -1
      : false;
    if (!obj) return { ...opt, count: 0, selected };
    const { count = 0 } = obj;
    return { ...opt, count, selected };
  });

  const suburbs = config?.suburbs?.map((opt) => {
    const { value, parentId } = opt;
    if (!value || !parentId) return { ...opt, selected: false, count: 0 };
    const obj = aggs?.suburbs?.find(
      (e) => e?.key === value && e?.parentKey == parentId
    );
    const selected = filter?.locations?.length
      ? filter?.locations?.findIndex(
          (e) => e?.county === parentId && e?.suburbs?.includes(value)
        ) > -1
      : false;
    if (!obj) return { ...opt, count: 0, selected };
    const { count = 0 } = obj;
    return { ...opt, count, selected };
  });

  const estateTypes = config?.estateTypes?.map((opt) => {
    const { value } = opt;
    if (!value) return opt;
    const obj = aggs?.estateTypes?.find((e) => e?.key === opt?.value);
    const selected = filter?.estateTypes?.includes(value) ?? false;
    if (!obj) return { ...opt, count: 0, selected };
    const { count = 0 } = obj;
    return { ...opt, count, selected };
  });

  return {
    assignmentTypes,
    counties,
    municipalities,
    suburbs,
    estateTypes,
    sold: {
      selectedValue: selectedSold,
      options: sold
    }
  };
};
