import * as React from 'react';
import styled from 'styled-components';
import ImageIcon from './ImageIcon';

interface IArgs {
  src?: string;
  alt?: string;
  loaded?: boolean;
  className?: string;
  classNameIcon?: string;
  showIcon?: boolean;
  onLoad?: () => void;
}

const Image: React.FC<IArgs> = ({
  src,
  alt,
  className,
  classNameIcon,
  showIcon = false,
  loaded = false,
  onLoad
}) => {
  const [error, setError] = React.useState(false);
  if (!src || error) return <ImageIcon className={classNameIcon} />;
  return (
    <>
      <Container className={`${className ?? ``} ${loaded ? 'show' : ''}`}>
        <Img
          data-src={src}
          src={src}
          alt={alt}
          onLoad={(e) => {
            e?.preventDefault();
            if (onLoad) onLoad();
          }}
        />
      </Container>
      {showIcon ? <ImageIcon hide={loaded} className={classNameIcon} /> : null}
    </>
  );
};

export default Image;

const Container = styled.div`
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;

  &.show {
    opacity: 1;
  }
`;

const Img = styled.img``;
