import * as React from 'react';
import styled from 'styled-components';

interface IArgs {
  value?: string;
  placeholder?: string;
  onChange: (value?: string) => void;
}

const FilterInput: React.FC<IArgs> = ({ placeholder, value, onChange }) => {
  const [text, setText] = React.useState(value ?? ``);
  return (
    <Container>
      <Input
        value={text}
        placeholder={placeholder ?? 'Søk i våre eiendommer...'}
        onChange={(e) => {
          e?.preventDefault();
          const value = e?.target?.value;
          setText(value);
          onChange(value?.trim()?.length > 1 ? value?.trim() : undefined);
        }}
      />
    </Container>
  );
};

export default FilterInput;

const Container = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`;

const Input = styled.input.attrs((props) => ({
  autoFocus: true
}))`
  display: flex;
  width: 100%;
  height: ${({ theme }) => theme.search.type.size1.fontSize};
  font-size: ${({ theme }) => theme.search.type.filter.fontSize};
  margin-bottom: 10px;
  background: #2a2a2a;
  color: #fff;
  border: 0;
  padding: 0 12px;
  border-radius: 0;
  -webkit-appearance: none;

  &:focus {
    outline: 0;
  }

  ${({ theme }) => theme.search.mediaQueries.tablet`
		height: 2.25rem;
		margin-bottom: 0; 
	`};
  //margin-top: 11px;

  ${({ theme }) => theme.search.mediaQueries.desktop`
		height: 2.25rem;
		margin-bottom: 0;	
	`};
  //margin-top: 11px;
`;
