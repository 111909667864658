const BASE_URL = `https://cdn.reeltime.no/pm_assets/img/cards/`;

export const images = {
  falk_bg: {
    mobile: `${BASE_URL}falk_bg.png`,
    desktop: `${BASE_URL}falk_bg@2x.png`
  },
  ulv_bg: {
    mobile: `${BASE_URL}ulv_bg.png`,
    desktop: `${BASE_URL}ulv_bg@2x.png`
  },
  panter_bg: {
    mobile: `${BASE_URL}panter2.jpg`,
    desktop: `${BASE_URL}panter2.jpg`
  },
  voice_bg: {
    mobile: `${BASE_URL}voice_bg.png`,
    desktop: `${BASE_URL}voice_bg@2x.png`
  },
  kjop_bg: {
    mobile: `${BASE_URL}kjop_bg.png`,
    desktop: `${BASE_URL}kjop_bg@2x.png`
  },
  kjop_logo: {
    mobile: `${BASE_URL}kjop_logo.png`,
    desktop: `${BASE_URL}kjop_logo@2x.png`
  },
  square: {
    mobile: `${BASE_URL}square.png`,
    desktop: `${BASE_URL}square@2x.png`
  },
  mic: {
    mobile: `${BASE_URL}mic.gif`,
    desktop: `${BASE_URL}mic.gif`
  },
  verdi: {
    mobile: `${BASE_URL}PM_verdi_500x500.jpg`,
    desktop: `${BASE_URL}PM_verdi_500x500.jpg`
  },
  notis: {
    mobile: `https://cdn.reeltime.no/pm_assets/notis/notisbg.jpg`,
    desktop: `https://cdn.reeltime.no/pm_assets/notis/notisbg.jpg`
  },
  boligbytte: {
    mobile: `${BASE_URL}PM_bb_500x500.jpg`,
    desktop: `${BASE_URL}PM_bb_500x500.jpg`
  },
  spoross: {
    mobile: `${BASE_URL}banner-spoross.jpg`,
    desktop: `${BASE_URL}banner-spoross.jpg`
  },
  selgeKjope: {
    mobile: `${BASE_URL}PM_pm.no_500x500-1.jpg`,
    desktop: `${BASE_URL}PM_pm.no_500x500-1.jpg`
  },
  selgeKjope2: {
    mobile: `${BASE_URL}PM_pm.no_500x500-2.jpg`,
    desktop: `${BASE_URL}PM_pm.no_500x500-2.jpg`
  },
  selgeKjope3: {
    mobile: `${BASE_URL}PM_pm.no_500x500-3.jpg`,
    desktop: `${BASE_URL}PM_pm.no_500x500-3.jpg`
  },
  selgeKjope4: {
    mobile: `${BASE_URL}PM_pm.no_500x500-4.jpg`,
    desktop: `${BASE_URL}PM_pm.no_500x500-4.jpg`
  },
  nordea_finans: {
    mobile: `${BASE_URL}PM_pm.no-5.jpg`,
    desktop: `${BASE_URL}PM_pm.no-5.jpg`
  },
  sommerTaktisk: {
    mobile: `${BASE_URL}PM_sommerTaktisk_500x500.jpg`,
    desktop: `${BASE_URL}PM_sommerTaktisk_500x500.jpg`
  },
  presisjon: {
    mobile: `${BASE_URL}PM_Presisjon_500x500.jpg`,
    desktop: `${BASE_URL}PM_Presisjon_500x500.jpg`
  },
  detaljerSomSelger1: {
    mobile: `${BASE_URL}Rosa-tak.jpg`,
    desktop: `${BASE_URL}Rosa-tak.jpg`
  },
  detaljerSomSelger2: {
    mobile: `${BASE_URL}Spiraltrapp.jpg`,
    desktop: `${BASE_URL}Spiraltrapp.jpg`
  },
  detaljerSomSelger3: {
    mobile: `${BASE_URL}Tredetaljer.jpg`,
    desktop: `${BASE_URL}Tredetaljer.jpg`
  },
  presisjon2: {
    mobile: `${BASE_URL}Egenannonser-PM.NO-Hage.jpg`,
    desktop: `${BASE_URL}Egenannonser-PM.NO-Hage.jpg`
  },
  forstegangsselgeren: {
    mobile: `${BASE_URL}Anders-og-susanne.jpg`,
    desktop: `${BASE_URL}Anders-og-susanne.jpg`
  },
  forstegangsselgeren2: {
    mobile: `${BASE_URL}bestemor.jpg`,
    desktop: `${BASE_URL}bestemor.jpg`
  },
  forstegangsselgeren3: {
    mobile: `${BASE_URL}brodre.jpg`,
    desktop: `${BASE_URL}brodre.jpg`
  },
  forstegangsselgeren4: {
    mobile: `${BASE_URL}Busnessdame.jpg`,
    desktop: `${BASE_URL}Busnessdame.jpg`
  },
  kjop: {
    mobile: `${BASE_URL}kjop.jpg`,
    desktop: `${BASE_URL}kjop.jpg`
  },
  artikkelsideNordea: {
    mobile: `${BASE_URL}Sofa-med-transparent-overgang.jpg`,
    desktop: `${BASE_URL}Sofa-med-transparent-overgang.jpg`
  }
};
