import * as React from 'react';
import styled from 'styled-components';
import { images } from '../data/Images';
import { IBanner } from '../data/Selection';
import { BannerContent } from './Content';
import { css } from 'styled-components';
import { fluidRange } from 'polished';

interface IArgs extends IBanner {
  type: string;
}

interface IBackground {
  desktop: string;
  mobile: string;
}

const Banner: React.FC<IArgs> = ({ type, bg, ...props }) => {
  const { className, hideContent = false } = props ?? {};
  return (
    <>
      <BannerContainer
        href={props?.link}
        bg={bg}
        className={`${className ?? ``} ${
          hideContent ? `no-content` : ``
        }`.trim()}
        hasText={props?.text && props?.text?.length > 0 ? true : false}
        onClick={() => {
          if (window.dataLayer) {
            window.dataLayer.push({
              event: 'PMCardClick',
              gaCategory: 'PMCardClick',
              gaAction: type,
              gaLabel: 'Searchpage'
            });
          }
        }}
      >
        {props?.square ? (
          <CardSquare>
            <BannerInner>
              {props?.title?.length ? (
                props?.smallTitle?.length ? (
                  <>
                    {type === 'voice' ? <CardsMic /> : null}
                    <SmallTitle>{props.smallTitle}</SmallTitle>
                    <Title>{props.title}</Title>
                  </>
                ) : (
                  <SquareTitle>{props?.title}</SquareTitle>
                )
              ) : null}
            </BannerInner>
          </CardSquare>
        ) : type === 'notis' ? (
          <BannerInner>
            <NewsTag />
            <SmallTitle style={{ marginTop: '65px' }}>{props.title}</SmallTitle>
            <NotisLogo />
            <ImageOverlay />
          </BannerInner>
        ) : (
          <BannerInner>
            {props?.title?.length ? (
              props?.smallTitle?.length ? (
                <>
                  <SmallTitle>{props.smallTitle}</SmallTitle>
                  <Title>{props.title}</Title>
                </>
              ) : props?.largeTitle ? (
                <BiggerTitle>{props.title}</BiggerTitle>
              ) : (
                <Title>{props.title}</Title>
              )
            ) : type === 'kjop' ? (
              <CardKjopsLogo
                src={images.kjop_logo.mobile}
                srcSet={`${images.kjop_logo.mobile} 1x, ${images.kjop_logo.desktop} 2x`}
              />
            ) : null}
          </BannerInner>
        )}
      </BannerContainer>
      {!hideContent ? <BannerContent type={type} {...props} /> : null}
    </>
  );
};

export default Banner;

const BannerContainer = styled.a<{ bg?: IBackground; hasText?: boolean }>`
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 100%;
  height: ${({ hasText }) => (hasText ? 'auto' : '80%')};
  overflow: hidden;
  background-image: ${({ bg }) => (bg ? `url(${bg.mobile})` : 'url()')};
  background-image: ${({ bg }) =>
    bg
      ? `
		-webkit-image-set(url(${bg.mobile}) 1x, url(${bg.desktop}) 2x)
	`
      : 'url()'};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  text-decoration: none;
  color: #fff;

  &.no-content {
    height: 100%;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transition: ease-in-out 500ms transform;
  }

  &:before {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  @media screen and (min-width: 1024px) {
    ${({ hasText }) =>
      hasText
        ? css`
            &:before {
              content: '';
              display: block;
              padding-bottom: 100%;
            }
          `
        : css`
            &:before {
              display: none;
            }
          `}
  }
`;

const BannerInner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

const CardSquare = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 250px;
  max-width: 550px;
  width: 75%;
  height: auto;
  background-image: url(${images.square.mobile});
  background-image: -webkit-image-set(
    url(${images.square.mobile}) 1x,
    url(${images.square.desktop}) 2x
  );
  background-size: cover;
  background-repeat: no-repeat;

  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  @media (min-width: 768px) and (max-width: 1280px) {
    min-width: 170px;
    max-width: 290px;
    ${fluidRange(
      {
        prop: 'width',
        fromSize: '180px',
        toSize: '290px'
      },
      '768px',
      '1280px'
    )};
  }

  @media (min-width: 1280px) and (max-width: 1440px) {
    min-width: 200px;
    max-width: 250px;
    ${fluidRange(
      {
        prop: 'width',
        fromSize: '200px',
        toSize: '250px'
      },
      '1280px',
      '1440px'
    )};
  }

  @media (min-width: 1440px) {
    min-width: 180px;
    max-width: 250px;
  }
`;

const SquareTitle = styled.h1`
  margin: 0;
  color: #fff;
  font-weight: 300;
  text-align: center;
  line-height: 1.2;
  padding: 18px;
  font-size: 24px;

  @media screen and (min-width: 768px) {
    ${fluidRange(
      {
        prop: 'font-size',
        fromSize: '20px',
        toSize: '24px'
      },
      '320px',
      '1440px'
    )};
  }

  @media screen and (min-width: 1280px) {
    ${fluidRange(
      {
        prop: 'font-size',
        fromSize: '20px',
        toSize: '24px'
      },
      '1280px',
      '1440px'
    )};
  }

  @media screen and (min-width: 1440px) {
    font-size: 20px;
  }
`;

const SmallTitle = styled.h2`
  margin: 0;
  max-width: 100%;
  line-height: 1.2;
  padding: 0 18px;
  text-align: center;
  font-size: 16px;

  @media screen and (min-width: 768px) {
    ${fluidRange(
      {
        prop: 'font-size',
        fromSize: '12px',
        toSize: '20px'
      },
      '320px',
      '1440px'
    )};
  }

  @media screen and (min-width: 1280px) {
    ${fluidRange(
      {
        prop: 'font-size',
        fromSize: '18px',
        toSize: '20px'
      },
      '1280px',
      '1440px'
    )};
  }

  @media screen and (min-width: 1440px) {
    font-size: 18px;
  }
`;

export const BiggerTitle = styled.h1`
  font-size: 1.5rem;
  margin: 0;
  max-width: 100%;
  line-height: 1.2;
  padding: 0 18px;
  text-align: center;
  color: #fff;
  font-weight: 300;
`;

const Title = styled.h1`
  margin: 0;
  max-width: 100%;
  line-height: 1.2;
  padding: 0 18px;
  text-align: center;
  font-size: 36px;
  color: #fff;
  font-weight: 300;

  @media screen and (min-width: 768px) {
    ${fluidRange(
      {
        prop: 'font-size',
        fromSize: '24px',
        toSize: '36px'
      },
      '320px',
      '1440px'
    )};
  }

  @media screen and (min-width: 1280px) {
    ${fluidRange(
      {
        prop: 'font-size',
        fromSize: '30px',
        toSize: '34px'
      },
      '1280px',
      '1440px'
    )};
  }

  @media screen and (min-width: 1440px) {
    font-size: 24px;
  }
`;

const CardsMic = styled.div`
  position: relative;
  min-width: 72px;
  max-width: 96px;
  margin-bottom: 9px;
  width: 75%;
  height: auto;
  background-image: url(${images.mic.mobile});
  background-size: cover;
  background-repeat: no-repeat;

  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  @media screen and (min-width: 768px) {
    min-width: 48px;
    max-width: 72px;
    ${fluidRange(
      {
        prop: 'width',
        fromSize: '72px',
        toSize: '96px'
      },
      '320px',
      '1040px'
    )};
  }
`;

const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: -1;
`;

const NotisLogo = styled.div`
  margin-top: 30px;
  width: 150px;
  height: 50px;
  background: url('https://cdn.reeltime.no/pm_assets/notis/notis.png') no-repeat;
  background-size: contain;
  background-position: center;
`;

const CardKjopsLogo = styled.img`
  &&& {
    position: relative;
    width: 100%;
    height: auto;
    margin-bottom: 0;
  }
`;

const NewsTag = styled.span`
  display: block;
  position: absolute;
  top: 0;
  right: 18px;
  width: 72px;
  height: 72px;
  background: url('https://cdn.reeltime.no/pm_assets/notis/nyhet-lapp3.png')
    no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 2;
`;
