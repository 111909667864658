import { ChecklistItem } from '../../../../__types__/generated/cluster';

export enum CheckListType {
  Downsized = 'Downsized',
  PmNotis = 'PmNotis',
  DobbelBoligforsikring = 'DobbelBoligforsikring',
  Event = 'Event',
  Teaser = 'Teaser'
}

interface IArgs {
  id?: string;
  type?: CheckListType;
  list?: ChecklistItem[];
}

export const checklistIsChecked = (args: IArgs): boolean | undefined => {
  const { id, type, list } = args;
  if (!list?.length || (!id && !type)) return;
  if (id) return list?.find((e) => e?.id === id)?.checked ?? false;
  return list?.find((e) => e?.type === type)?.checked ?? false;
};
