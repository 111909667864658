import * as React from 'react';
import styled from 'styled-components';
import { IOptionItem } from '../types/Index';
import { CheckIcon } from '../asset/CheckIcon';

interface IArgs {
  label: string;
  value: string | number;
  count?: number;
  checked: boolean;
  onClick: (value: string | number) => void;
  subOptions?: IOptionItem<any>[];
  onSubClick?: (value: string | number) => void;
}

const Checkbox: React.FC<IArgs> = ({
  label,
  value,
  checked,
  count,
  onClick,
  children,
  subOptions,
  onSubClick
}) => {
  return (
    <Container>
      <Label
        onClick={() => {
          onClick(value);
        }}
      >
        <Checkmark className={checked ? `checked` : ``}>
          <CheckIcon />
        </Checkmark>
        <Text>
          {label} {count ? <Count>({count})</Count> : null}
        </Text>
      </Label>
      {subOptions && onSubClick && checked
        ? subOptions?.map((sub, index) => {
            const { label, value, count, selected: checked } = sub;
            if (!value) return null;
            const name = label ?? value ?? ``;
            return (
              <Container nested first={index === 0} key={`sub${index}`}>
                <Label
                  onClick={() => {
                    onSubClick(value);
                  }}
                >
                  <Checkmark className={checked ? `checked` : ``}>
                    <CheckIcon />
                  </Checkmark>
                  <Text>
                    {name} {count ? <Count>({count})</Count> : null}
                  </Text>
                </Label>
              </Container>
            );
          })
        : null}
    </Container>
  );
};

export default Checkbox;

const Container = styled.div<{ nested?: boolean; first?: boolean }>`
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-flow: column;
  width: 100%;
  margin-top: ${({ nested, first }) => (nested && first ? '12px' : '0')};
  margin-bottom: 10px;
  margin-left: ${({ nested }) => (nested ? '35px' : '0')};

  ${({ theme, nested }) =>
    nested
      ? theme.search.mediaQueries.tablet`
    margin-bottom: 0;
    margin-top: 0;
    margin-left: 27px;
  `
      : theme.search.mediaQueries.tablet`
    margin-bottom: 0;
    margin-top: 0;
    margin-left: 0;
  `}

  ${({ theme, nested }) =>
    nested
      ? theme.search.mediaQueries.desktop`
    margin-bottom: 0;
    margin-top: 0;
    margin-left: 27px;
    `
      : theme.search.mediaQueries.desktop`
    margin-bottom: 0;
    margin-top: 0;
    margin-left: 0;
  `};
`;

const Label = styled.label`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  cursor: pointer;

  &:before {
    content: '';
    display: inline-block;
    width: ${({ theme }) => theme.search.type.size3.fontSize};
    height: ${({ theme }) => theme.search.type.size3.fontSize};
    background: ${({ theme }) => theme.search.colors.grey.dark};
  }

  ${({ theme }) => theme.search.mediaQueries.tablet`
    &:before { 
      width: 1rem;
      height: 1rem;
    }
  `};

  ${({ theme }) => theme.search.mediaQueries.desktop`
  &:before {
    width: 1rem;
    height: 1rem;
  }
  `};
`;

const CheckboxInput = styled.input`
  display: none;
  &:checked + ${Label} {
    svg {
      display: flex;
    }
  }
`;

const Checkmark = styled.span`
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ theme }) => theme.search.type.size3.fontSize};
  height: ${({ theme }) => theme.search.type.size3.fontSize};

  svg {
    display: none;
    width: ${({ theme }) => theme.search.type.size4.fontSize};
    height: ${({ theme }) => theme.search.type.size4.fontSize};

    path {
      fill: ${({ theme }) => theme.search.colors.gold.primary};
    }
  }

  ${({ theme }) => theme.search.mediaQueries.tablet`
		top: 4px;
		left: 0;
		width: 1rem;
		height: 1rem;

		svg {
			display: none;
			width: 0.82rem;
			height: 0.82rem;

			path {
				fill: #E7CFA7
			}
		}
	`};

  ${({ theme }) => theme.search.mediaQueries.desktop`
		top: 4px;
		left: 0;
		width: 1rem;
		height: 1rem;

		svg {
			display: none;
			width: 0.82rem;
			height: 0.82rem;

			path {
				fill: #E7CFA7;
			}
		}
	`};

  &.checked {
    svg {
      display: flex;
    }
  }
`;

const Text = styled.span`
  margin-left: 12px;
  font-size: ${({ theme }) => theme.search.type.filter.fontSize};
`;

const Count = styled.span`
  font-size: 13px;
  opacity: 0.4;
`;
