import {
  SearchEstateSort,
  SearchFilterEstate
} from '../../../../__types__/generated/cluster';

export const getFilterDefault = (args?: {
  published?: boolean;
  limit?: number;
}): SearchFilterEstate => {
  const { published, limit } = args ?? {};
  return {
    aggregations: true,
    published: published,
    isProjectUnit: false,
    sort: SearchEstateSort.PublishedNewOld,
    pagination: { limit: limit ?? 50, page: 0 }
  };
};
