import { getConfigDefault } from '../getConfigDefault';
import { getFilterDefault } from '../getFilterDefault';
import { getQueryObject } from './getQueryObject';

export const getInit = (value?: string) => {
  const config = getConfigDefault();
  const filter = getFilterDefault({
    published: config?.published,
    limit: config?.pagnation?.limit
  });
  const obj = getQueryObject(value);
  if (!obj) return { config, filter, query: null };
  const {
    available,
    counties,
    locations,
    mapView,
    minBedroom,
    objectTypes,
    pageIndex,
    priceFrom,
    priceTo,
    query,
    show_market_link,
    sizeFrom,
    sizeTo,
    sold,
    subdealTypes
  } = obj;

  config.pagnation.page = pageIndex ?? 0;
  filter.bedrooms =
    minBedroom && minBedroom > 0 ? { from: minBedroom } : undefined;

  if (counties?.length || locations?.length) {
    const { locations: areas = [] } = filter;

    if (counties?.length) {
      counties
        ?.filter((e) => e)
        ?.forEach((e) => {
          const county = typeof e === 'string' ? e : e?.county;
          if (county && areas?.findIndex((e) => e?.county === value) < 0) {
            areas.push({ county, municipalities: [], suburbs: [] });
          }
        });
    }

    if (locations?.length) {
      locations
        ?.filter((e) => e && typeof e !== 'string')
        ?.forEach((obj) => {
          if (typeof obj !== 'string') {
            const { county, id, municipalityArea } = obj;
            if (county && id) {
              const idx = areas?.findIndex((e) => e?.county === county);
              switch (county?.toLowerCase()) {
                case 'oslo':
                  {
                    if (idx < 0) {
                      areas.push({ county, municipalities: [], suburbs: [id] });
                    } else if (!areas[idx]?.suburbs?.includes(id)) {
                      const { suburbs = [] } = areas[idx];
                      suburbs?.push(id);
                      areas[idx].suburbs = suburbs;
                    }
                  }
                  break;
                default:
                  {
                    if (idx < 0) {
                      areas.push({ county, municipalities: [id], suburbs: [] });
                    } else if (!areas[idx]?.municipalities?.includes(id)) {
                      const { municipalities = [] } = areas[idx];
                      municipalities?.push(id);
                      areas[idx].municipalities = municipalities;
                    }
                  }
                  break;
              }
            }
          }
        });
    }

    filter.locations = areas;
  }

  if ((available || sold) && available != sold) {
    if (available) config.sold.selectedValue = '0';
    if (sold) config.sold.selectedValue = '1';
    filter.sold = sold;
  }

  if (priceFrom && priceFrom > config?.price?.min) {
    filter.price = {
      ...filter.price,
      from: priceFrom
    };
  }

  if (priceTo && priceTo < config?.price?.max) {
    filter.price = {
      ...filter.price,
      to: priceTo
    };
  }

  if (sizeFrom && sizeFrom > config?.size?.min) {
    filter.size = {
      ...filter.size,
      from: sizeFrom
    };
  }

  if (sizeTo && sizeTo < config?.size?.max) {
    filter.size = {
      ...filter.size,
      to: sizeTo
    };
  }

  if (subdealTypes?.length) {
    config.assignmentTypes.forEach((o) => {
      o.selected =
        o?.label &&
        subdealTypes?.findIndex(
          (e) => e?.toUpperCase() === o?.label?.toUpperCase()
        ) > -1
          ? true
          : false;
    });

    filter.assignmentTypes = config.assignmentTypes
      ?.filter((o) => o?.selected && o?.value?.length)
      ?.map((o) => o?.value ?? [])
      ?.reduce((pV, cV) => {
        if (cV?.length) pV.push(...cV);
        return pV;
      }, []);
  }

  if (objectTypes?.length) {
    filter.estateTypes = objectTypes;
  }

  return { filter, query };
};
