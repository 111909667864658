import * as React from 'react';
import styled from 'styled-components';
import { IBanner } from '../data/Selection';

interface IArgs extends IBanner {
  type: string;
  disableReadMore?: boolean;
}

export const BannerContent: React.FC<IArgs> = ({
  title,
  text,
  type,
  link,
  disableReadMore
}) => {
  return (
    <Content
      onClick={() => {
        if (window.dataLayer) {
          window.dataLayer.push({
            event: 'PMCardClick',
            gaCategory: 'PMCardClick',
            gaAction: type,
            gaLabel: 'Searchpage'
          });
        }
      }}
    >
      {text?.length ? <p>{text}</p> : null}
      {type !== 'detaljerSomSelger1' &&
      type !== 'detaljerSomSelger2' &&
      type !== 'detaljerSomSelger3' &&
      !disableReadMore ? (
        <Button href={link} hasText={text?.length ? true : false}>
          Les mer
        </Button>
      ) : null}

      {type === 'detaljerSomSelger1' ||
      type === 'detaljerSomSelger2' ||
      (type === 'detaljerSomSelger3' && !disableReadMore) ? (
        <Button href={link} hasText={text?.length ? true : false}>
          Bli inspirert
        </Button>
      ) : null}
    </Content>
  );
};

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  width: 100%;
  padding: 1.5rem;
  text-align: center;

  p {
    max-width: 100%;
  }
`;

const Button = styled.a<{ hasText: boolean }>`
  display: block;
  min-width: 120px;
  text-align: center;
  padding: 12px 24px;
  margin-top: ${({ hasText }) => (hasText ? '18px' : '0')};
  background: rgb(232, 200, 147);
  color: #000;
  border: 0;
  cursor: pointer;
  text-decoration: none;
`;
