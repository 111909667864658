import * as React from 'react';
import styled from 'styled-components';

interface IArgs {
  show?: boolean;
  showFinnLink?: boolean;
  isMapView?: boolean;
  total: number;
  page: number;
  itemsPrPage: number;
  setPage: (page: number) => void;
  onMouseEnter?: any;
}

interface IPagnator {
  currentIndex: number;
  totalPages: number;
  maxItems: number;
  maxPages: number;
  startIndex: number;
  endIndex: number;
  pages: number[];
}

const getPagnationData = (args: {
  total: number;
  itemsPrPage: number;
  currentPageIndex: number;
}): IPagnator => {
  const { total, itemsPrPage, currentPageIndex } = args;
  const totalPages = Math.ceil(total / itemsPrPage);
  const currentIndex =
    currentPageIndex < 0
      ? 1
      : currentPageIndex > totalPages
      ? totalPages
      : currentPageIndex;
  const maxItems = 10000;
  const maxPages = Math.ceil(maxItems / itemsPrPage);
  const minPages = 10;

  let startIndex = 0;
  let endIndex = totalPages;

  if (totalPages > minPages) {
    if (currentIndex < 6) {
      endIndex = minPages;
    } else if (currentIndex + 4 >= totalPages) {
      startIndex = totalPages - minPages;
    } else {
      startIndex = currentIndex - 5;
      endIndex = currentIndex + 5;
    }
  }
  const pages = Array.from(Array(endIndex - startIndex).keys()).map(
    (i) => startIndex + i
  );

  return {
    currentIndex,
    startIndex,
    endIndex,
    totalPages,
    maxItems,
    maxPages,
    pages
  };
};

export const Pagination: React.FC<IArgs> = ({
  show = false,
  showFinnLink = false,
  isMapView = false,
  total,
  page,
  itemsPrPage,
  setPage,
  onMouseEnter
}) => {
  if (!itemsPrPage) return null;
  const [pagnator, setPagnator] = React.useState<IPagnator>(
    getPagnationData({ total, itemsPrPage, currentPageIndex: page })
  );

  React.useEffect(() => {
    setPagnator(
      getPagnationData({ total, itemsPrPage, currentPageIndex: page })
    );
  }, [total, page, itemsPrPage]);

  const { currentIndex, pages, maxPages, totalPages } = pagnator;

  if (totalPages < 2) return null;

  return (
    <>
      {show ? (
        <Wrapper>
          <Button
            disabled={currentIndex < 1}
            onClick={() => {
              setPage(0);
            }}
          >
            Første
          </Button>
          <Button
            className="mobile"
            disabled={currentIndex < 1}
            onClick={() => {
              setPage(currentIndex - 1);
            }}
          >
            Forrige
          </Button>
          {pages?.length
            ? pages.map((i) =>
                i <= maxPages ? (
                  <Item
                    key={`page${i}`}
                    className={i === currentIndex ? `current` : ``}
                    onClick={() => {
                      setPage(i);
                    }}
                  >
                    {i + 1}
                  </Item>
                ) : null
              )
            : null}
          <Button
            className="mobile"
            disabled={currentIndex === totalPages - 1}
            onClick={() => {
              setPage(currentIndex + 1);
            }}
          >
            Neste
          </Button>
          <Button
            disabled={currentIndex === totalPages - 1}
            onClick={() => {
              setPage(totalPages - 1);
            }}
          >
            Siste
          </Button>
        </Wrapper>
      ) : null}
    </>
  );
};

export default Pagination;

const Wrapper = styled.ul`
  display: flex;
  flex-flow: row nowrap;
  margin: 0;
  padding: 0 0 24px 0;
  flex: 1;
  list-style: none;
  justify-content: center;
`;

const Item = styled.li`
  display: none;
  align-items: center;
  justify-content: center;
  min-width: 30px;
  height: 36px;
  margin-right: 6px;
  padding: 0 6px;
  color: #fff;
  background: ${({ theme }) => theme.search.colors.primary3Light};
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.search.colors.primary3LightHover};
    color: ${({ theme }) => theme.search.colors.gold.primary};
  }

  ${({ theme }) => theme.search.mediaQueries.desktopMd`
	  display: flex;
	`}

  &.current {
    color: #000;
    background: ${({ theme }) => theme.search.colors.gold.primary};
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 36px;

  ${({ theme }) => theme.search.mediaQueries.desktopMd`
	  flex-flow: row wrap;
  `};
`;

const Button = styled.button`
  display: none;
  align-items: center;
  justify-content: center;
  min-height: ${({ theme }) => theme.search.type.size1.fontSize};
  padding: 0 12px;
  margin-right: 6px;
  border: 0;
  background: ${({ theme }) => theme.search.colors.grey.darkest};
  color: #fff;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &:hover {
    color: ${({ theme }) => theme.search.colors.gold.primary};
  }

  &:focus {
    outline: none;
  }

  /*&:nth-of-type(2) {
    display: flex;
    flex: 1;
  }

  &:last-of-type {
    display: flex;
    flex: 1;
    margin-right: 0;
  }*/

  &.mobile {
    display: flex;
    flex: 1;
  }

  ${({ theme }) => theme.search.mediaQueries.desktopMd`
    display: flex;
    flex: 0;
    min-height: 36px;

    &.mobile {
      display: flex;
      flex: 0;
    }
  `}
`;
