import styled from 'styled-components';
import { StyledH4 } from '../types/Index';
import { lighten } from 'polished';

export const Filters = styled.aside<{ mapView: boolean; show: boolean }>`
  position: fixed;
  left: ${({ mapView }) => (mapView ? '0' : '0')};
  top: 66px;
  display: ${({ show }) => (show ? 'block' : 'none')};
  width: ${({ mapView }) => (mapView ? '100%' : '100%')};
  max-width: 100vw;
  height: calc(100% - 66px);
  margin-right: 0;
  padding: ${({ mapView }) =>
    mapView ? '1rem 1rem 2rem 1rem' : '0 1rem 2rem 1rem'};
  background: ${({ mapView, theme }) =>
    mapView ? theme.search.colors.primary3 : '#171719'};
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 500;
  -webkit-overflow-scrolling: touch;

  ${({ theme }) => theme.search.mediaQueries.tablet`
		position: relative;
		display: block;
		top: auto;
		left: 0;
		height: 100%;
		min-width: 300px;
		max-width: 300px;
		margin-right: 2rem;

		&& {
			padding-left: 0;
		}

    &.map {
      position: fixed;
      top: 72px;
      height: calc(100% - 72px);

      && {
        padding-left: 1.5rem;
      }
    }
	`}

  ${({ theme }) => theme.search.mediaQueries.desktop`
		position: relative;
		top: auto;
		left: 0;
		height: 100%;
		margin-right: 2rem;
		display: block;
		min-width: 380px;
		max-width: 380px;
		z-index: 10;

		&& {
			padding-left: 0;
		}

    &.map {
      position: fixed;
      top: 72px;
      height: calc(100% - 72px);

      && {
        padding-left: 1.5rem;
      }
    }
	`}
`;

export const SearchHeader = styled.div`
  position: sticky;
  top: 0;
  display: block;
  width: 100%;
  background: #000;
  z-index: 1;

  ${({ theme }) => theme.search.mediaQueries.tablet`
		position: relative;
		top: auto;
		background: transparent;
	`}
  ${({ theme }) => theme.search.mediaQueries.desktop`
		position: relative;
		top: auto;
		background: transparent;
	`}
`;

export const List = styled.ul`
  margin: 0;
  padding-left: 0;
  padding: 0;
  list-style: none;
`;

export const ListItem = styled.li`
  :not(:last-child) {
    margin-bottom: 0.2em;
  }
`;

export const ListHeader = styled(StyledH4)`
  font-family: 'Geograph-Regular', sans-serif;
  padding-top: 1rem;
  margin: 0px 0px 0.5rem;
  font-weight: normal;
  color: ${({ theme }) => theme.search.colors.gold.primary};

  &:first-of-type {
    padding: 0.75rem 0px 0px;
  }
`;

export const DivInfo = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: 1rem;
  line-height: 1.5;
`;

export const FilterToggles = styled.div<{ mapView: boolean }>`
  position: ${({ mapView }) => (mapView ? 'fixed' : 'sticky')};
  left: 0;
  top: 66px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  flex: 1;
  width: ${({ mapView }) => (mapView ? '100%' : 'auto')};
  padding: ${({ mapView }) =>
    !mapView ? '0px 0px 0.75rem' : '0 1rem 1rem 1rem'};
  z-index: 10;
  background: #171719;
  gap: 10px;
  margin-bottom: 10px;

  .dropdown {
    display: ${({ mapView }) => (mapView ? 'none' : 'block')};
  }

  ${({ theme, mapView }) =>
    mapView
      ? theme.search.mediaQueries.tablet`
		display: none;
		position: relative;
		top: auto;
		background: transparent;
    margin-bottom: 0;

		button, a {
			margin-bottom: 0;
		}
	`
      : theme.search.mediaQueries.tablet`
		display: flex;
		position: relative;
		top: auto;
		background: transparent;
    margin-bottom: 0;

		button, a {
			margin-bottom: 0;
		}
	`};

  ${({ theme, mapView }) =>
    mapView
      ? theme.search.mediaQueries.desktop`
		display: none;
		position: relative;
		top: auto;
		background: transparent;
    margin-bottom: 0;

		button, a {
			margin-bottom: 0;
		}
	`
      : theme.search.mediaQueries.desktop`
		display: flex;
		position: relative;
		top: auto;
		background: transparent;
    margin-bottom: 0;

		button, a {
			margin-bottom: 0;
		}
	`};

  ${({ theme }) => theme.search.mediaQueries.mobile`
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    margin-bottom: 0;

    button, a, input {
      margin: 0;
      width: 100%;
    }
  `};
`;

export const SpinnerContainer = styled.div`
  display: flex;
  width: 36px;
  height: 36px;
  align-items: center;
  justify-content: center;
  margin-right: 9px;
`;

export const MapHeader = styled.div`
  display: none;
  flex-flow: row nowrap;
  align-items: center;
  margin-bottom: 12px;

  ${({ theme }) => theme.search.mediaQueries.tablet`
		display: flex;
	`};
  ${({ theme }) => theme.search.mediaQueries.desktop`
		display: flex;
	`};
`;

export const MapTitle = styled.h3`
  margin: 0;
  flex: 1;
  color: ${({ theme }) => theme.search.colors.gold.primary};
`;

export const FilterButton = styled.button<{ mobile?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  min-width: auto;
  color: white;
  font-size: ${({ theme }) => theme.search.type.filter.fontSize};
  height: ${({ theme }) => theme.search.type.size1.fontSize};
  ${({ dangerouslySetInnerHTML }) =>
    dangerouslySetInnerHTML ? null : 'padding: 0px 0.75rem;'};
  background: ${({ theme }) => theme.search.colors.primary3Light};
  border: none;
  //color: ${({ theme }) => lighten(0.4, theme.search.colors.grey.dark)};
  text-decoration: none;
  cursor: pointer;

  &.fullWidth {
    min-width: 100%;
  }

  &.primary {
    background: ${({ theme }) => theme.search.colors.gold.primary};
    color: #000;
  }

  &:focus {
    outline: 0;
  }

  &:hover {
    background: ${({ theme }) => theme.search.colors.gold.primary};
    color: #000;

    svg {
      path {
        fill: #000;
      }
    }
  }

  svg {
    display: flex;
    width: auto;
    height: ${({ theme }) => theme.search.type.size3.fontSize};

    path {
      fill: ${({ theme }) => lighten(0.4, theme.search.colors.grey.dark)};
    }
  }
  ${({ theme, mobile }) =>
    mobile
      ? theme.search.mediaQueries.tablet`
        display: none;
      `
      : theme.search.mediaQueries.tablet`
        display: flex;
        min-width: 100px;
        flex: 1;
        height: 2.25rem;

        &.noFlex {
          flex: 0;
        }
	  `};

  ${({ theme, mobile }) =>
    mobile
      ? theme.search.mediaQueries.desktop`
        display: none;
      `
      : theme.search.mediaQueries.desktop`
        display: flex;
        min-width: 100px;
        flex: 1;
        height: 2.25rem;

        &.noFlex {
          flex: 0;
        }
	  `};
`;

export const CloseMapButtonDesktop = styled(FilterButton)`
  margin-left: auto;
  && {
    flex: 0;
  }
`;

export const SaveButton = styled(FilterButton)`
  order: 1;
  ${({ theme }) => theme.search.mediaQueries.tablet`
		order: 0;
		margin-bottom: 10px;
	`};

  ${({ theme }) => theme.search.mediaQueries.desktop`
		order: 0;
		margin-bottom: 10px;
	`};
`;

export const MapToggle = styled(FilterButton)`
  order: 1;
  ${({ theme }) => theme.search.mediaQueries.tablet`
		order: 0;
		margin-bottom: 10px;
	`};

  ${({ theme }) => theme.search.mediaQueries.desktop`
		order: 0;
		margin-bottom: 10px;
	`};
`;

export const ButtonIcon = styled.div`
  display: flex;
  width: ${({ theme }) => theme.search.type.size3.fontSize};
  height: ${({ theme }) => theme.search.type.size3.fontSize};
  margin-right: 6px;
  svg {
    display: flex;
    width: ${({ theme }) => theme.search.type.size3.fontSize};
    height: ${({ theme }) => theme.search.type.size3.fontSize};

    path {
      fill: ${({ theme }) => lighten(0.4, theme.search.colors.grey.dark)};
    }
  }
`;
