import * as React from 'react';
import styled from 'styled-components';

export const CheckIcon: React.FC = () => {
  return (
    <svg
      width="100pt"
      height="100pt"
      version="1.1"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m5.0586 53.211 9.7383-12.508 26.016 21.457 42.754-46.883 11.84 10.613-52.934 58.473z" />
    </svg>
  );
};
