import * as React from 'react';
import styled from 'styled-components';
import Banner from './banner/Index';
import { IBanner, bannerSelection } from './data/Selection';

interface IArgs {
  type: string;
  externalContainer?: React.FC;
}

const AdCard: React.FC<IArgs> = ({
  type,
  externalContainer: ExternalContainer
}) => {
  const getBannerComponent = () => {
    const banner: IBanner | undefined = getAdData(type);
    if (!banner) return null;
    return <Banner {...banner} type={type} />;
  };
  const content = getBannerComponent();
  if (!content) return null;

  if (ExternalContainer) {
    return <ExternalContainer>{content}</ExternalContainer>;
  }

  return <Container>{content}</Container>;
};

export default AdCard;

const Container = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  background: #181819;
`;

const getAdTypes = () => {
  const active: string[] = [];
  const arr = Object.entries(bannerSelection);
  const filtered = arr.filter(([key, item]) => item?.id !== 1 && item?.active);
  for (let [k, v] of filtered) {
    if (v.active) {
      active.push(k);
    }
  }
  return active;
};

const getAdData = (type: string): IBanner | undefined => {
  const arr = Object.entries(bannerSelection);
  if (!arr?.length) return;
  const [key, item] =
    arr?.find(([key, item]) => key === type && item?.active) ?? [];
  if (key && item?.id) return item;
  return;
};

export const adUtil = {
  getAdTypes,
  getAdData
};
