interface IObject {
  id: number;
  name: string;
}

const objectTypes: string[] = [
  'Enebolig',
  'Tomannsbolig',
  'Leilighet',
  'Rekkehus',
  'Gårdsbruk/småbruk',
  'Bygård',
  'Rom i bofelleskap',
  'Hybel',
  'Garasje/parkering',
  'Annet',
  'Tomter',
  'Hytte',
  'Hyttetomt',
  'Produksjon/industri'
];

export const getSelectedObjectTypes = (value?: string | string[] | null) => {
  if (!value) return [];
  const array = (
    typeof value === 'string'
      ? [parseInt(value)]
      : value?.map((e) => parseInt(e))
  )?.filter((e) => !isNaN(e));
  if (!array?.length) return [];
  return array
    ?.map((i) => {
      if (i >= 0 && i < objectTypes?.length) return objectTypes[i];
      return ``;
    })
    ?.filter((e) => e?.length);
};

export const getObjectTypeIndex = (value?: string) => {
  if (!value || value?.trim()?.length === 0) return -1;
  return objectTypes?.findIndex(
    (e) => e?.toLowerCase() === value?.toLowerCase()
  );
};
