import * as React from 'react';
import styled from 'styled-components';

interface IArgs {
  items: IItem[];
  selectedValue?: string | number;
  handleChange: (selected?: string | number) => void;
}

interface IItem {
  value: string | number;
  label: string;
}

export const RadioButtons: React.FC<IArgs> = ({
  items,
  selectedValue,
  handleChange
}) => {
  if (!items?.length) return null;
  return (
    <Container>
      {items?.map((item, index) => {
        return (
          <Item
            key={`radio${index}`}
            selected={selectedValue === item?.value}
            onClick={() => {
              if (selectedValue === item.value) {
                handleChange();
              } else {
                handleChange(item.value);
              }
            }}
          >
            {item.label}
          </Item>
        );
      })}
    </Container>
  );
};

const Container = styled.ul`
  display: flex;
  flex-flow: row nowrap;
  margin: 0;
  padding: 0;
  list-style: none;
`;
const Item = styled.li<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  min-height: ${({ theme }) => theme.search.type.size1.fontSize};
  background: ${({ theme, selected }) =>
    selected
      ? theme.search.colors.gold.primary
      : theme.search.colors.primary3Light};
  color: ${({ selected }) => (selected ? '#000' : '#fff')};
  border-right: 1px solid #000;
  cursor: pointer;

  &:last-child {
    border-right: 0;
  }

  &:hover {
    background: ${({ theme, selected }) =>
      selected
        ? theme.search.colors.gold.primary
        : theme.search.colors.grey.dark};
    color: ${({ selected }) => (selected ? '#000' : '#fff')};
  }

  ${({ theme }) => theme.search.mediaQueries.tablet`
    min-height: 2.25rem;
  `};

  ${({ theme }) => theme.search.mediaQueries.desktop`
    min-height: 2.25rem;
  `};
`;

export default RadioButtons;
