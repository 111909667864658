import qs from 'querystring';
import { SearchFilterEstate } from '../../../../../__types__/generated/cluster';
import { IConfig } from '../../types/Index';
import { getObjectTypeIndex } from './getSelectedObjectTypes';

interface IArgs {
  config?: IConfig;
  query?: string | null;
  filter?: SearchFilterEstate;
}

export const setQuery = (args?: IArgs) => {
  const { query = null, filter, config } = args ?? {};
  if (!(query || filter)) return;

  const record: Record<
    string,
    number | string | boolean | undefined | number[]
  > = {
    pfv: getMinValue(filter?.price?.from, config?.price?.min),
    ptv: getMaxValue(filter?.price?.to, config?.price?.max),
    sfv: getMinValue(filter?.size?.from, config?.size?.min),
    stv: getMaxValue(filter?.size?.to, config?.size?.max),
    mb: filter?.bedrooms?.from ?? undefined,
    s: filter?.sold !== undefined ? filter?.sold : true,
    a: filter?.sold !== undefined ? !filter?.sold : true,
    q: query && query?.trim()?.length > 0 ? query : undefined,
    ob: getObjectTypes(filter?.estateTypes)
  };

  const obj = Object.keys(record)?.reduce((pV, key) => {
    if (key && record[key] === undefined) return pV;
    return {
      ...pV,
      [key]: record[key]
    };
  }, {});
  if (Object?.keys(obj)?.length < 1) return;
  const q = qs?.stringify(obj);
  if (!q) return;
  return q;
};

const getMinValue = (value?: number, min?: number) => {
  if (!value || value <= 0) return min;
  if (!min || min <= 0) return value;
  if (value < min) return min;
  return value;
};

const getMaxValue = (value?: number, max?: number) => {
  if (!value || value <= 0) return max;
  if (!max || max <= 0) return value;
  if (value > max) return max;
  return value;
};

const getObjectTypes = (values: string[] | undefined) => {
  if (!values?.length) return;
  const array: number[] = [];
  values?.forEach((e) => {
    const idx = getObjectTypeIndex(e);
    if (idx > -1 && !array?.includes(idx)) array.push(idx);
  });
  if (!array?.length) return;
  return array;
};
