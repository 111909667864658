import styled from 'styled-components';
import { animated } from 'react-spring';
import { SearchEstateSort } from '../../../../__types__/generated/cluster';

export interface IConfig {
  total: number | null;
  price: IRange;
  size: IRange;
  published?: boolean;
  sold: IDropdown<string>;
  counties: IOptionItem<string>[];
  municipalities: IOptionItem<string>[];
  suburbs: IOptionItem<string>[];
  pagnation: IPagnation;
  sort: IDropdown<SearchEstateSort>;
  estateTypes: IOptionItem<string>[];
  assignmentTypes: IOptionItem<string[]>[];
}

interface IPagnation {
  limit: number;
  page: number;
}

interface IDropdown<T> {
  selectedValue: T | null;
  options: IOptionItem<T>[];
}

export interface IOptionItem<T> {
  label: string;
  value: T | null;
  count?: number;
  selected?: boolean;
  parentId?: string;
}

interface IRange {
  min: number;
  max: number;
  step: number;
}

export interface IAdCard {
  type: string;
  adIndex: number;
}

export const Container = styled.div`
  position: relative;
  display: block;
  flex-flow: row wrap;
  padding: 8rem 1rem 0px;
  align-content: flex-start;
  min-height: 100vh;

  ${({ theme }) => theme.search.mediaQueries.tablet`
		display: flex;
		padding: 12rem 1.5rem 0px;
	`};

  ${({ theme }) => theme.search.mediaQueries.desktop`
		display: flex;
    padding: 12rem 1.5rem 0px;
	`};
`;

export const StyledH1 = styled.h1`
  font-size: ${({ theme }) => theme.search.type.size1.fontSize};
  line-height: ${({ theme }) => theme.search.type.size1.lineHeight};
  margin: ${({ theme }) => theme.search.type.size1.margin};
`;

export const StyledH2 = styled.h2`
  font-size: ${({ theme }) => theme.search.type.size2.fontSize};
  line-height: ${({ theme }) => theme.search.type.size2.lineHeight};
  margin: ${({ theme }) => theme.search.type.size2.margin};
`;

export const StyledH4 = styled.h4`
  font-size: ${({ theme }) => theme.search.type.size4.fontSize};
  line-height: ${({ theme }) => theme.search.type.size4.lineHeight};
  margin: ${({ theme }) => theme.search.type.size4.margin};
`;

export const SectionHeader = styled(animated.div)`
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;
  padding-bottom: 0;
  text-align: center;

  ${({ theme }) => theme.search.mediaQueries.tablet`
		padding-bottom: 3rem;
	`};

  ${({ theme }) =>
    theme.search.mediaQueries.desktop({
      paddingBottom: '3rem'
    })};
`;

export const HeaderTitle = styled(StyledH1)`
  margin-top: 0;
  font-size: 36px;
  font-weight: normal;
  max-width: 10ch;
  margin-bottom: 0;

  ${({ theme }) => theme.search.mediaQueries.tablet`
    font-size: 48px;
    max-width: auto;
  `};

  ${({ theme }) => theme.search.mediaQueries.desktop`
    font-size: 48px;
    max-width: auto;
  `};
`;

export const ResultsContainer = styled.div`
  position: relative;
  display: block;
  flex-flow: row wrap;
  flex: 1;
  align-content: flex-start;
  max-width: 100%;
`;

export const Results = styled.ul`
  display: flex;
  flex-flow: row wrap;
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
`;

export const ResultCount = styled.span`
  color: ${({ theme }) => theme.colors.white};
  display: none;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 24px 0 18px;
  text-align: center;

  strong {
    margin-right: 0.5ch;
  }

  ${({ theme }) => theme.search.mediaQueries.tablet`
		display: inline-flex;
		width: auto;
		margin-left: auto;
		padding: 0;
	`};

  ${({ theme }) => theme.search.mediaQueries.desktop`
		display: inline-flex;
		width: auto;
		margin-left: auto;
		padding: 0;
	`};
`;

export const TextInputContainer = styled.div`
  display: flex;
  width: 100%;

  div:first-child  {
    flex: 1;
  }
`;
