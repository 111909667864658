import * as React from 'react';
import styled from 'styled-components';

export const ChevronIcon: React.FC = () => {
  return (
    <svg
      width="100pt"
      height="100pt"
      version="1.1"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m50 65.172 2.6562-2.3125 25-22-5.3125-6.0312-22.344 19.656-22.344-19.656-5.3125 6.0312 25 22z" />
    </svg>
  );
};
