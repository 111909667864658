import { AssignmentType } from '../../../../__types__/generated/cluster';

export const isNewDevelopment = (assignmentType?: AssignmentType) => {
  if (!assignmentType) return false;
  const whitelist = [
    AssignmentType.NewDevelopment,
    AssignmentType.NewDevelopmentCommercial,
    AssignmentType.NewDevelopmentLeisure
  ];
  return whitelist.includes(assignmentType);
};
