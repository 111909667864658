import * as React from 'react';
import styled from 'styled-components';
import { Prices } from '../../../../__types__/generated/cluster';
import { currency } from '../../../../utils/format/currency';

export const Price: React.FC<{ prices?: Partial<Prices> }> = ({ prices }) => {
  if (!prices || !prices?.askingPrice) return <Container />;
  if (prices?.askingPrice && prices?.askingPriceTo) {
    return (
      <Container>
        {`${currency({ number: prices?.askingPrice })} - ${currency({
          number: prices?.askingPriceTo
        })},-`}
      </Container>
    );
  }

  return (
    <Container>{`${currency({ number: prices?.askingPrice })},-`}</Container>
  );
};

const Container = styled.span`
  color: ${({ theme }) => theme.search.colors.gold.primary};
  margin: 1rem 0px 0px;
`;
