import * as React from 'react';
import styled from 'styled-components';
import { Range, getTrackBackground } from 'react-range';

interface IArgs {
  min: number;
  max: number;
  selected: {
    from?: number;
    to?: number;
  };
  step: number;
  showInfo?: boolean;
  printValue?: (args: { value: number; isFromValue?: boolean }) => string;
  handleChange: (from: number, to: number) => void;
}

interface IValues {
  from: number;
  to: number;
}

const RangeSlider: React.FC<IArgs> = ({
  min,
  max,
  step,
  selected,
  showInfo,
  printValue,
  handleChange
}) => {
  const [values, SetValues] = React.useState<IValues>({
    from: min ?? 0,
    to: max ?? 0
  });
  if (min >= max) return null;
  if (max - min < step) return null;
  React.useEffect(() => {
    SetValues({ from: selected?.from ?? min, to: selected?.to ?? max });
  }, [selected?.from, selected?.to]);

  const onPrint = (value: number, isFromValue: boolean) => {
    if (printValue) return printValue({ value, isFromValue });
    return value;
  };

  return (
    <Container>
      {showInfo ? (
        <Info
          dangerouslySetInnerHTML={{
            __html: `${
              values?.from && values?.from > min ? 'Fra' : 'Under'
            } ${onPrint(values?.from ?? min, true)} - ${
              values?.to && values?.to < max ? `` : 'Over '
            }${onPrint(values?.to ?? max, false)}`
          }}
        />
      ) : null}
      <RangeContainer>
        <Range
          min={min}
          max={max}
          values={[values?.from, values?.to]}
          step={step}
          onChange={([from, to]) => {
            //handleChange(from, to);
            SetValues({ from, to });
          }}
          onFinalChange={([from, to]) => {
            handleChange(from, to);
          }}
          renderTrack={({ props, children }) => (
            <Track
              {...props}
              style={{
                ...props.style,
                background: getTrackBackground({
                  min: min,
                  max: max,
                  values: [values?.from, values?.to],
                  colors: ['#242525', '#e8c893', '#242525']
                })
              }}
            >
              {children}
            </Track>
          )}
          renderThumb={({ props }) => (
            <Thumb {...props} style={{ ...props.style }} />
          )}
        />
      </RangeContainer>
    </Container>
  );
};

export default RangeSlider;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Info = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.search.type.filter.fontSize};
  line-height: 1.5;
`;

const RangeContainer = styled.div`
  padding: 27px 21px;
  width: 100%;
  display: flex;
  justify-content: end;
  font-size: 100%;

  ${({ theme }) => theme.search.mediaQueries.tablet`
    padding: 17px 12px;
  `};

  ${({ theme }) => theme.search.mediaQueries.desktop`
    padding: 17px 12px;
  `};
`;

const Track = styled.div`
  background-color: #e8c893;
  width: 100%;
  height: 4px;
  border-radius: 6px;
`;

const Thumb = styled.div`
  width: 42px;
  height: 42px;
  background-color: #171719;
  border: 3px solid #e8c893;
  border-radius: 50%;

  ${({ theme }) => theme.search.mediaQueries.tablet`
    width: 24px;
    height: 24px;
  `};

  ${({ theme }) => theme.search.mediaQueries.desktop`
    width: 24px;
    height: 24px;
  `};
`;
