import { gql } from '@apollo/client';

export const SEARCH_ESTATE = gql`
  query SearchEstate(
    $input: SearchInput!
    $filter: SearchFilterEstate
    $finn: Boolean
  ) {
    search(input: $input) {
      estate(filter: $filter) {
        total
        aggregations {
          assignmentTypes {
            count
            key
            label
          }
          counties {
            count
            key
            label
          }
          municipalities {
            count
            key
            label
            parentKey
          }
          suburbs {
            count
            key
            label
            parentKey
          }
          estateTypes {
            count
            key
            label
          }
          bedrooms {
            count
            key
            label
          }
          sold {
            count
            key
            label
          }
          facilities {
            key
            label
            count
          }
        }
        list {
          estateId
          type
          isProjectMaster
          ownershipType
          assignmentType
          assignmentTypeMapped
          assignmentNumber
          streetAddress
          municipalityArea
          municipalityName
          countyName
          localArea
          postalPlace
          sold
          soldAt
          published
          publishedAt
          images(finn: $finn) {
            list {
              imageId
              url
            }
          }
          prices {
            askingPrice
            askingPriceTo
          }
          size {
            usableArea
            usableAreaTo
            primaryArea
            primaryAreaTo
            grossArea
            grossAreaTo
            grossInternalArea
            grossInternalAreaTo
          }
          plot {
            size
          }
          projectStatistics {
            available
            sold
            total
          }
          checklist {
            type
            checked
          }
        }
      }
    }
  }
`;
