import * as React from 'react';
import styled from 'styled-components';
import { Estate } from '../../../../__types__/generated/cluster';
import Carousel from './image/Carousel';
import { Price } from './Price';
import { ProjectStatInfo } from './ProjectStatInfo';
import { util } from '../util';
import { CheckListType } from '../util/checklistIsChecked';
import { Area } from '../../../ui/cards/cluster/Area';

export const PropertyCard: React.FC<{ estate: Partial<Estate> }> = ({
  estate
}) => {
  const [scaleImg, setScaleImg] = React.useState(false);
  if (!estate?.estateId) return null;
  const notis = util.checklistIsChecked({
    type: CheckListType.PmNotis,
    list: estate?.checklist
  });
  const teaser = util.checklistIsChecked({
    type: CheckListType.Teaser,
    list: estate?.checklist
  }) && !estate?.published;
  return (
    <CardContainer>
      <CardLink
        scaleImg={scaleImg}
        href={`https://privatmegleren.no/${estate?.assignmentNumber}`}
      >
        {estate?.sold ? (
          <SoldTag />
        ) : util.isNewDevelopment(estate?.assignmentTypeMapped) ? (
          <NybyggTag />
        ) : null}
        {/*notis ? <NotisTag /> : null*/}
        <Carousel estateId={estate?.estateId} list={estate?.images?.list ?? []}>
          <ProjectStatInfo
            teaser={teaser}
            statistics={estate?.projectStatistics}
          />
        </Carousel>
        <Content>
          <Location>{estate?.localArea ?? estate?.municipalityArea}</Location>
          {estate?.countyName ? <County>{estate?.countyName}</County> : null}
          <Span>{estate?.streetAddress}</Span>
          <Span>
            {estate?.type ? `${estate?.type} - ` : null}
            <Area size={estate?.size} plot={estate?.plot?.size} />
          </Span>
          <Price prices={estate?.prices} />
        </Content>
      </CardLink>
    </CardContainer>
  );
};

export const CardContainer = styled.li`
  position: relative;
  display: flex;
  flex-flow: column;
  width: 100%;
  height: auto;
  margin: 0px 0px 1.5rem 0px;
  background: ${({ theme }) => theme.search.colors.primary3Light};
  transition: ease-in-out 90ms background;

  &:hover {
    background: ${({ theme }) => theme.search.colors.primary3LightHover};
  }

  ${({ theme }) => theme.search.mediaQueries.tabletPortrait`
		width: calc(50% - 0.75rem);
		margin: 0px 1.5rem 1.5rem 0px;

		&:nth-child(2n + 2) {
			margin-right: 0;
		}
	`}

  ${({ theme }) => theme.search.mediaQueries.tablet`
		width: calc(50% - 0.75rem);
		margin: 0px 1.5rem 1.5rem 0px;

		&:nth-child(2n + 2) {
			margin-right: 0;
		}
	`}

	${({ theme }) => theme.search.mediaQueries.desktop`
		width: calc(50% - 0.75rem);
		margin: 0px 1.5rem 1.5rem 0px;

		&:nth-child(2n + 2) {
			margin-right: 0;
		}

	`}

	${({ theme }) => theme.search.mediaQueries.desktopLg`
		width: calc(100% / 3 - (1.5rem) / 3 * 2);
    margin: 0px 1.5rem 1.5rem 0px;

		&:nth-child(2n + 2) {
      margin: 0px 1.5rem 1.5rem 0px;
		}		
		
		&:nth-child(3n + 3) {
			margin-right: 0;
		}
	`}

	${({ theme }) => theme.search.mediaQueries.desktopXl`
		width: calc(100% / 4 - (1.5rem) / 4 * 3);
    margin: 0px 1.5rem 1.5rem 0px;

		&:nth-child(2n + 2) {
			margin: 0px 1.5rem 1.5rem 0px;
		}		
		
		&:nth-child(3n + 3) {
			margin: 0px 1.5rem 1.5rem 0px;
		}

		&:nth-child(4n + 4) {
			margin-right: 0;
		}
	`}
`;

const CardLink = styled.a<{ scaleImg: boolean }>`
  display: block;
  position: relative;
  text-decoration: none;
  color: #fff;

  .img-container {
    img {
      transform: ${({ scaleImg }) => (scaleImg ? 'scale(1.2)' : 'scale(1)')};
    }
  }
`;

const ImageContainer = styled.div`
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  overflow: hidden;
  background: #333;
  &:hover {
    .left,
    .right {
      display: block;
    }
  }

  .left,
  .right {
    display: none;
  }

  &:before {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transition: ease-in-out 500ms transform;
  }
`;

const Content = styled.div`
  display: flex;
  flex-flow: column;
  padding: 1.5rem;
  span {
    display: block;
    max-width: 100%;
    overflow-wrap: break-word;
  }
`;

const Location = styled.span`
  color: ${({ theme }) => theme.search.colors.gold.primary};
`;

const County = styled.span`
  color: ${({ theme }) => theme.search.colors.gold.primary};
  margin-bottom: 1rem;
`;

const Span = styled.span``;

const NotisTag = styled.span`
  display: block;
  position: absolute;
  top: 0;
  right: 18px;
  width: 72px;
  height: 72px;
  background: url('https://cdn.reeltime.no/pm_assets/notis/notis-lapp.png')
    no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 2;
`;

const NybyggTag = styled.span`
  display: block;
  position: absolute;
  top: 0;
  right: 18px;
  width: 72px;
  height: 72px;
  background-image: url('https://reeltimeprod-ahebbuatfggte7f2.z01.azurefd.net/privatmegleren/nybygg_tag.png');
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 2;
`;

const SoldTag = styled(NybyggTag)`
  background-image: url('https://reeltimeprod-ahebbuatfggte7f2.z01.azurefd.net/privatmegleren/soldmark.jpg');
`;
